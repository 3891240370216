import { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const useGetWithRetries = (url, includeFirebaseAuth = false, includeRetries = false, retryOptions = {}) => {
    const { currentUser } = useAuth();

    const {
        requestConditionHandler,
        maxRequests,
        retryDelay
    } = retryOptions;

    const initialState = {
        data: null,
        isLoading: true,
        hasError: false,
        requestCount: 0
    }
    const [state, setState] = useState(initialState);
    const {
        data,
        isLoading,
        hasError,
        requestCount
    } = state;

    const getData = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            // to do - possibly make currentUser optional
            if (includeFirebaseAuth && currentUser) {
                const tokenIdResult = await currentUser.getIdTokenResult();
                const newToken = tokenIdResult?.token;
                headers['Authorization'] = `Bearer ${newToken}`;
            }
            const res = await axios.get(url, { headers });
            setState(prevState => ({
                ...prevState,
                data: res.data,
                requestCount: prevState.requestCount + 1
            }));
            if (includeRetries && requestCount < maxRequests && requestConditionHandler(res.data?.proposal)) {
                setTimeout(getData, retryDelay);
            } else {
                setState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))
            }
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                hasError: true
            }));
            console.log({ errorGetData: error });
            Sentry.captureException(error);
        }
    };

    useEffect(() => {
        try {
            getData();
        } catch (error) {
            console.log({ errorUseGetWithRetries: error });
            Sentry.captureException(error);
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                hasError: true
            }));
        }
    }, [url]);

    useEffect(() => {
        console.log("RETRY COUNT: " + requestCount);
    }, [requestCount]);

    return { data, isLoading, hasError, requestCount };
};

export default useGetWithRetries;
