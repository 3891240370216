function getPreviousMonth() {
    const currentDate = new Date();
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    const monthName = previousMonth.toLocaleString('default', { month: 'long' });
    return monthName;
}

module.exports = {
    getPreviousMonth
}
