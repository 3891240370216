import React, { useState } from 'react'
import MuiModal from './MuiModal';
import LoadingIcon from './LoadingIcon';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

export default function SignatureModal(props) {
    const {
        open,
        proposalId
    } = props;
    const initialState = {
        isLoading: false,
        error: ''
    }
    const handleClose = e => {
        !!e && e.preventDefault();
        setState(initialState);
        props.handleClose();
    }
    const { currentUser } = useAuth();
    const [state, setState] = useState(initialState);
    const handleTriggerSignature = async e => {
        try {
            e.preventDefault();
            console.log("HANDLE SIGNATURE");
            setState(prevState => ({
                ...prevState,
                isLoading: true
            }));
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/proposals/${proposalId}/contract`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
            handleClose();
        } catch (error) {
            console.log(error);
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                error: "There has been an error.  Please contact support."
            }));
        }
    }
    const {
        isLoading,
        error
    } = state;
    if (isLoading) {
        return (
            <MuiModal
                open={open}
                onClose={handleClose}
            >
                <LoadingIcon />
            </MuiModal>
        )

    } else if (error) {
        return (
            <MuiModal
                open={open}
                onClose={handleClose}
            >
                <p id="parent-modal-description" style={{ textAlign: "center", marginBottom: "40px" }}>
                    {error}
                </p>
            </MuiModal>
        )

    } else {
        return (
            <MuiModal
                open={open}
                onClose={handleClose}
            >
                <p id="parent-modal-description" style={{ textAlign: "center", marginBottom: "40px" }}>
                    Are you sure you want to send the contract to the customer?
                </p>
                <p style={{ textAlign: "center", marginBottom: "40px" }} >Note - this currently sends a test document and replaces the customer's email with "olivia@resoursify.com", until the real contract is in place.</p>
                <div className="button-container-vertical">
                    <button className='button-default button-primary' onClick={handleTriggerSignature}>Yes</button>
                    <button className='button-default button-secondary' onClick={handleClose}>Cancel</button>
                </div>
            </MuiModal>
        )
    }

}
