import React, { useState, useRef } from 'react';
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

import TreepublicTitleImage from '../images/treepublic_logo_large.png';

import NoticeCard from '../components/NoticeCard';

import ArrowRightSolid from "../icons/arrow-right-solid.svg";
import LoadingIcon from '../components/LoadingIcon';
import TreepublicLogoClickable from '../components/TreepublicLogoClickable';

export default function ForgotPasswordPage() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setLoading(false);
      setMessage("Please check your email for instructions to reset your password.  Thank you!");
    } catch (error) {
      console.log(error);
      setError('Failed to reset password. Please reach out to support. Thank you!');
      Sentry.captureException(error);
    }
    setLoading(false);
  }

  function handleRedirect(e, route) {
    e.preventDefault();
    navigate(route);
  }

  return (
    <>
      <div className="flex-vertical-center h-30vh">
        {TreepublicTitleImage && <TreepublicLogoClickable route="/" />}
        {!TreepublicTitleImage && <h2 className="mb-1 image-small">Treepublic</h2>}
      </div>
      <div className="container-rounded-top responsive-width-100-50">
        {(isLoading) && (
          <LoadingIcon />
        )}
        {(!isLoading) && (
          <>
            <form className="text-center p-0-4 flex-vertical flex-grow-8" onSubmit={handleSubmit}>
              <p className='ubuntu dark f-2'>Password Reset</p>
              {error && <NoticeCard text={error} type="warning" />}
              {message && <NoticeCard text={message} type="success" />}
              <div className='w-100'>
                <input
                  className="styled-input mt-3"
                  placeholder="Email..."
                  id="email"
                  aria-label="email address"
                  ref={emailRef}
                />
              </div>

              <button className="button-default button-primary" type="submit">Submit</button>

            </form>
            <p className="text-center flex-center flex-grow-2 mt-1 mb-1"><a className='fw-light grey underline' onClick={e => handleRedirect(e, '/login')}>Log in</a></p>
          </>
        )}
      </div>
    </>
  )
}
