export default function Stepper(props) {
    const {
        nextLabelOverride,
        disableNext,
        handleNext,
        handleBack,
        stages,
        stage
    } = props;

    const currentIndex = stages.findIndex((item) => item === stage);
    const completed = Math.round(((currentIndex + 1) / (stages.length + 1)) * 100);

    return (
        <div className='stepper'>
            <button
                className="button-default button-secondary"
                onClick={handleBack}
                disabled={currentIndex === 0}
            >
                Back
            </button>
            <div className="stepper-grey">
                <div className="stepper-completed" style={{ width: completed + "%" }}>
                    <p className="stepper-percentage" style={{ color: (completed > 45) ? "#1e1e1e" : "white" }}>{completed + "%"}</p>
                </div>
            </div>
            <button
                className="button-default button-primary"
                onClick={handleNext}
                disabled={(stage === (stages.length - 1)) || !!disableNext}
            >
                {!!nextLabelOverride ? nextLabelOverride : "Next"}
            </button>
        </div >
    )
}
