function getSystemSizeFromSystem(system) {
    if (!!system) {
        if (!!system.output) {
            return (system.output / 1000).toFixed(2)
        }
    }
}

function getPanelModelFromSystem(system) {
    return system?.panels?.model;
}

function getAnnualProductionFromSystem(system) {
    if (!!system) {
        return system?.production?.yearly;
    }
}

function getNumberOfPanelsFromSystem(system) {
    return system?.panels?.quantity;
}

function getWattsPerPanelFromSystem(system) {
    return system?.panels?.wattage;
}

module.exports = {
    getSystemSizeFromSystem,
    getPanelModelFromSystem,
    getAnnualProductionFromSystem,
    getNumberOfPanelsFromSystem,
    getWattsPerPanelFromSystem
}
