const batteries = require('../data/batteries.json');

const getBatteryCostByName = name => {
    if (name) {
        const battery = batteries.find(item => item.name === name);
        if (battery) {
            return battery.cost;
        }
    }
    return null;
}

const getBatteryCapacityByName = (name, unit = "kWh") => {
    let capacity;
    if (name) {
        const battery = batteries.find(item => item.name === name);
        if (battery) {
            capacity = battery.capacity;
        }
    }
    if (!!capacity) {
        if (unit === "kWh") {
            if (!!capacity) {
                capacity = (capacity / 1000);
            }
        }
    }
    return capacity;
}

module.exports = {
    getBatteryCostByName,
    getBatteryCapacityByName
}
