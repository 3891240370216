import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledInput from '../components/StyledInput'
import Container from './Container';
import LoadingIcon from '../components/LoadingIcon';
import GoogleMaps from '../components/GoogleMaps';
import useGoogleMapsApiKey from '../hooks/googleMaps';
import batteries from '../data/batteries.json';
import roofing from '../data/roofing.json';
import utilities from '../data/utilities.json';
import { getBatteryCostByName, getBatteryCapacityByName } from '../services/batteries';
import Usage from '../components/Usage';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { getPreviousMonth } from '../services/time';
import Stepper from '../components/Stepper';
import UploadFiles from '../components/UploadFiles';
import MuiModal from '../components/MuiModal';
import { formatPhone } from '../services/phone';
import useProposalForm from '../hooks/useProposalForm';

export default function ProposalForm() {
    const navigate = useNavigate();

    const previousMonth = getPreviousMonth();

    const stages = [
        'initial',
        'solar and/or battery',
        'usage',
        'open solar iframe',
        'customer information',
        'miscellaneous questions'
    ];

    const initialState = {
        stage: 'initial',
        hasError: false,
        isLoading: false,
        isLoadingIframe: false,
        showModalOpenSolar: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        roofType: '',
        wantsSolar: false,
        wantsBattery: false,
        batteryName: '',
        batteryCost: '',
        usageType: 'Yearly Usage',
        usageYearly: '',
        usageMonthly: '',
        usageMonth: previousMonth,
        billMonthly: '',
        electricVehicle: '',
        projectNotes: '',
        utilityCompany: '',
        utilityCompanyOther: '',
        bills: []
    }
    console.log(initialState);
    const [state, setState] = useState(initialState);

    const { googleMapsApiKey, isLoadingGoogleMapsApiKey, hasErrorGoogleMapsApiKey } = useGoogleMapsApiKey("test param");

    const {
        id,
        proposalId,
        openSolarIframeSrc,
        isLoadingSubmission,
        hasErrorSubmission,
        submitSyncSystemDesign,
        submitInitialForm,
        submitFinalForm
    } = useProposalForm();

    console.log("USE PROPOSAL FORM ID: " + id);

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const handleChangePhone = e => {
        let formattedPhone = formatPhone(e.target.value);
        setState({
            ...state,
            phone: formattedPhone
        });
    }

    const handleChangeBatteryName = e => {
        const batteryCostByName = getBatteryCostByName(e.target.value);
        const batteryCapacityByName = getBatteryCapacityByName(e.target.value);
        setState({
            ...state,
            wantsBattery: !!wantsBattery || !!e.target.value,
            batteryName: e.target.value,
            batteryCost: batteryCostByName || "",
            batteryCapacity: batteryCapacityByName || ""
        });
    }

    function handleToggle(e) {
        setState({
            ...state,
            [e.target.name]: !state[e.target.name]
        })
    }

    function handleUpdateState(data) {
        if (!!data && typeof data === "object") {
            setState(prevState => ({
                ...prevState,
                ...data
            }));
        }
    }

    const handleNext = e => {
        e.preventDefault();

        const additionalStateUpdates = {};
        const currentStage = state.stage;

        if (currentStage === "solar and/or battery" && !state.wantsBattery) {
            additionalStateUpdates["batteryName"] = "";
            additionalStateUpdates["batteryCost"] = "";
            additionalStateUpdates["batteryCapacity"] = "";
        }
        if (currentStage === "initial") {
            handleSubmitInitial(e);
        } else if (currentStage === "open solar iframe") {
            handleCompleteSystemDesign(e);
        } else if (currentStage === "miscellaneous questions") {
            handleSubmitFinal(e);
        } else {
            if (!!currentStage) {
                const currentIndex = stages.findIndex((item) => item === currentStage);
                if (!!currentIndex) {
                    let nextStage = stages[currentIndex + 1];
                    if (nextStage === "open solar iframe" && !state.wantsSolar) {
                        nextStage = stages[currentIndex + 2];
                    }
                    setState({
                        ...state,
                        ...additionalStateUpdates,
                        stage: nextStage
                    })
                }
            }
        }
    }

    const handleBack = e => {
        e.preventDefault();
        const currentStage = state.stage;
        if (!!currentStage) {
            const currentIndex = stages.findIndex((item) => item === currentStage);
            if (!!currentIndex) {
                let prevStage = stages[currentIndex - 1];
                if (prevStage === "open solar iframe" && !state.wantsSolar) {
                    prevStage = stages[currentIndex - 2];
                }
                setState({
                    ...state,
                    stage: prevStage
                });

            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [state.stage]);

    // to do - can refactor
    const handleCompleteSystemDesign = async e => {
        e.preventDefault();

        // no need to await this
        //   We want the user to go to the next stage of the form without setting any loading or error UI updates
        submitSyncSystemDesign(proposalId);

        setState({
            ...state,
            stage: 'customer information',
            showModalOpenSolar: false
        })
    }

    const handleSubmitInitial = async e => {
        e.preventDefault();
        console.log("handleSubmitInitial");
        if (!!id) {
            console.log("don't submit, as the lead has already been created");
            setState({
                ...state,
                stage: 'solar and/or battery'
            });
            return;
        }
        submitInitialForm(state, () => {
            setState(prevState => ({
                ...prevState,
                stage: 'solar and/or battery',
                isLoadingIframe: true
            }));
        });
    }

    const handleSubmitFinal = async e => {
        e.preventDefault();
        console.log("handleSubmitFinal");
        submitFinalForm({ ...state, id }, () => {
            navigate(`/${proposalId}`);
        });
    }

    function saveAddressInState(data) {
        console.log("saveAddressInState");
        if (!!data && typeof data === "object") {
            setState({
                ...state,
                ...data
            });
        }
    }

    function handleIframeLoaded() {
        console.log("iframe has loaded!");
        setState({
            ...state,
            isLoadingIframe: false
        });
    }

    function handleOpenModal(e, name) {
        !!e && e.preventDefault();
        setState(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    function handleCloseModals(e) {
        !!e && e.preventDefault();
        setState(prevState => ({
            ...prevState,
            showModalOpenSolar: false
        }));
    }

    const {
        stage,
        isLoading,
        isLoadingIframe,
        hasError,
        firstName,
        lastName,
        email,
        phone,
        address,
        lat,
        lng,
        zoom,
        roofType,
        wantsSolar,
        wantsBattery,
        batteryName,
        usageType,
        usageYearly,
        usageMonthly,
        usageMonth,
        billMonthly,
        electricVehicle,
        projectNotes,
        utilityCompany,
        utilityCompanyOther,
        bills,
        showModalOpenSolar
    } = state;
    const enableSubmitAddress = !!address;
    const enableSubmitSolarBatteryCheckboxes = !!wantsSolar || !!wantsBattery;
    const enableSubmitCustomerInformation = !!firstName && !!lastName && !!email && !!phone;
    const enableSubmitRoof = !!roofType;
    const enableSubmitMiscellaneousQuestions = (!!batteryName) || (!!wantsSolar && !wantsBattery);
    const enableSubmitUsage = (
        !!utilityCompany
        && ((utilityCompany === "Other" && !!utilityCompanyOther)
            || utilityCompany !== "Other"
        )
        && ((usageType === "Yearly Usage" && !!usageYearly)
            || (usageType === "Monthly Usage" && !!usageMonth && !!usageMonthly)
            || (usageType === "Monthly Bill" && !!billMonthly)
        ));
    if (hasError || hasErrorGoogleMapsApiKey || hasErrorSubmission) {
        return (
            <Container responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading || isLoadingGoogleMapsApiKey || isLoadingSubmission) {
        return (
            <Container responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else if (stage === "initial") {
        return (
            <Container responsive>
                <GoogleMaps
                    address={address}
                    lat={lat}
                    lng={lng}
                    updateLocation={saveAddressInState}
                    zoom={zoom}
                    googleMapsApiKey={googleMapsApiKey}
                />
                <Stepper
                    disableNext={!enableSubmitAddress}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    stages={stages}
                    stage={stage}
                />
            </Container>
        )
    } else if (stage === "solar and/or battery") {
        return (
            <Container responsive>
                <form
                    className="p-1"
                    style={{ height: "calc(100vh - 60px - 32px - 72px - 32px - 32px)" }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={wantsSolar}
                                    onChange={handleToggle}
                                    name="wantsSolar"
                                    id="wantsSolar"
                                />
                            }
                            label="Solar"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={wantsBattery}
                                    onChange={handleToggle}
                                    name="wantsBattery"
                                    id="wantsBattery"
                                />
                            }
                            label="Battery"
                        />
                    </FormGroup>
                </form>
                <Stepper
                    disableNext={!enableSubmitSolarBatteryCheckboxes}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    stages={stages}
                    stage={stage}
                />
            </Container>
        )
    } else if (stage === "usage") {
        return (
            <Container responsive>
                <form
                    className="p-1"
                >
                    <p className='mb-1 text-medium'>Roof Type</p>
                    <StyledInput
                        name="roofType"
                        id="roofType"
                        value={roofType}
                        onChange={handleChange}
                        mb
                        type="dropdown"
                        required
                    >
                        <option value="" disabled>Select roof type</option>
                        {roofing.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </StyledInput>
                    <p className="mb-4 text-medium bold">Usage</p>
                    <div>
                        <p className="mb-1 text-medium">Utility Company</p>
                        <select
                            className='styled-input'
                            name="utilityCompany"
                            id="utilityCompany"
                            value={utilityCompany}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select Utility Company</option>
                            {utilities.map((name, index) => (
                                <option key={`utility - ${index} -${name} `} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>
                        {utilityCompany === "Other" && (
                            <>
                                <p className="mb-1 text-medium">Input Utility Name</p>
                                <input
                                    className='styled-input'
                                    name="utilityCompanyOther"
                                    id="utilityCompanyOther"
                                    placeholder="Input Utility Name"
                                    type="text"
                                    value={utilityCompanyOther}
                                    onChange={handleChange}
                                    mb
                                    required
                                />
                            </>
                        )}
                    </div>
                    <div className='mt-2 mb-4'>
                        <UploadFiles
                            bills={bills}
                            handleUpdateState={handleUpdateState}
                        />
                    </div>
                    <Usage
                        handleUpdateState={handleUpdateState}
                        usageType={usageType}
                        usageYearly={usageYearly}
                        usageMonthly={usageMonthly}
                        usageMonth={usageMonth}
                        billMonthly={billMonthly}
                    />


                </form>

                <Stepper
                    disableNext={!enableSubmitUsage}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    stages={stages}
                    stage={stage}
                />
            </Container >
        )
    } else if (stage === "open solar iframe") {
        return (
            <>
                <MuiModal
                    open={!!showModalOpenSolar}
                    onClose={handleCloseModals}
                >
                    <p className='mb-1 text-medium'>Don't forget!</p>
                    <p className='mb-4'>Save your Open Solar design using the yellow "save" button in the upper right.</p>
                    <button
                        className='button-primary button-default'
                        onClick={handleNext}
                    >
                        I saved my design
                    </button>
                </MuiModal>
                <Container hideLogo>
                    <div className="fit-screen flex-vertical">
                        {isLoadingIframe && (
                            <>
                                <LoadingIcon fitScreen color="#ffd900" />
                            </>
                        )}
                        <iframe
                            title="Open Solar"
                            className="vertical-container-minus-stepper"
                            style={{ width: '100%' }}
                            src={openSolarIframeSrc}
                            onLoad={handleIframeLoaded}
                        />
                        <div>
                            <p className="text-center mt-1 mb-1">Please make sure to save the design on Open Solar before proceeding</p>
                            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <Stepper
                                    handleNext={e => handleOpenModal(e, "showModalOpenSolar")}
                                    handleBack={handleBack}
                                    stages={stages}
                                    stage={stage}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        )
    } else if (stage === "customer information") {
        return (
            <Container responsive>
                <form
                    className="p-1"
                >
                    <p className="mt-3 mb-3 p-0-4">Please provide basic customer information</p>
                    <p className='mb-1 text-medium'>First Name</p>
                    <input
                        className='styled-input'
                        name="firstName"
                        id="firstName"
                        placeholder="Enter first name"
                        type="text"
                        value={firstName}
                        onChange={handleChange}
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Last Name</p>
                    <input
                        className='styled-input'
                        name="lastName"
                        id="lastName"
                        placeholder="Enter last name"
                        type="text"
                        value={lastName}
                        onChange={handleChange}
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Email</p>
                    <input
                        className='styled-input'
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        type="email"
                        value={email}
                        onChange={handleChange}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                        required
                    />
                    <p className='mb-1 text-medium'>Phone</p>
                    <input
                        className='styled-input'
                        label="Phone"
                        name="phone"
                        id="phone"
                        placeholder="Enter phone"
                        type="tel"
                        value={phone}
                        onChange={handleChangePhone}
                        required
                    />
                    <Stepper
                        disableNext={!enableSubmitCustomerInformation}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        stages={stages}
                        stage={stage}
                    />
                </form>
            </Container>
        )
    } else if (stage === "miscellaneous questions") {
        return (
            <>
                <Container responsive>
                    <form
                        onSubmit={handleSubmitFinal}
                    >
                        <p className='mb-1 text-medium'>Project Notes</p>
                        <textarea
                            className='styled-input inter'
                            value={projectNotes}
                            name="projectNotes"
                            placeholder="Project Notes"
                            type="textarea"
                            onChange={handleChange}
                            rows="5"
                        />
                        {(!!wantsBattery || !!electricVehicle || !!batteryName) && <div>
                            <p className='mb-1 text-medium'>Battery</p>
                            <select
                                className='styled-input'
                                value={batteryName}
                                name="batteryName"
                                placeholder="Battery Name"
                                type="text"
                                onChange={handleChangeBatteryName}
                            >
                                <option value="">Select battery</option>
                                {batteries.map((item, index) => (
                                    <option key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>}
                        <Stepper
                            disableNext={!enableSubmitMiscellaneousQuestions}
                            handleNext={handleNext}
                            nextLabelOverride="Submit"
                            handleBack={handleBack}
                            stages={stages}
                            stage={stage}
                        />
                    </form>
                </Container>
            </>
        )
    }
}
