function formatPhone(input) {
    if (!!input) {
        let inputValue = input.replace(/\D/g, '');

        // Format the phone number
        let formattedPhone = '';
        if (inputValue.length > 0) {
            formattedPhone = '(' + inputValue.slice(0, 3);
        }
        if (inputValue.length >= 4) {
            formattedPhone += ') ' + inputValue.slice(3, 6);
        }
        if (inputValue.length >= 7) {
            formattedPhone += '-' + inputValue.slice(6, 10);
        }
        return formattedPhone;
    }
    return '';
}

module.exports = {
    formatPhone
}