import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const useGoogleMapsApiKey = () => {
    const auth = useAuth();
    const currentUser = auth?.currentUser;
    const [state, setState] = useState({
        googleMapsApiKey: '',
        isLoadingGoogleMapsApiKey: true,
        hasErrorGoogleMapsApiKey: false,
    });

    useEffect(() => {
        const fetchGoogleMapsApiKey = async () => {
            console.log("fetchGoogleMapsApiKey");
            try {
                const tokenIdResult = await currentUser?.getIdTokenResult();
                const newToken = tokenIdResult?.token;
                const res = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/google-maps`,
                    {
                        headers: {
                            'Authorization': `Bearer ` + newToken,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                console.log("response received");
                console.log(res.data.googleMapsApiKey);
                setState({
                    ...state,
                    googleMapsApiKey: res.data.googleMapsApiKey,
                    isLoadingGoogleMapsApiKey: false,
                });
            } catch (error) {
                console.log({ errorUseGoogleMapsApiKey: error });
                setState({
                    ...state,
                    hasErrorGoogleMapsApiKey: true,
                });
            }
        };

        fetchGoogleMapsApiKey();
    }, []);

    return state;
};

export default useGoogleMapsApiKey;
