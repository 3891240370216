import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Container from './Container';
import useGet from '../hooks/useGet';
import LoadingIcon from '../components/LoadingIcon';
import StyledInput from '../components/StyledInput';
import { useAuth } from '../contexts/AuthContext';
import { getLoanTypeFromLead, getPricePerWattFromLead, getAddersValueFromLead, getAddersNotesFromLead } from '../services/leads';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

export default function PricingPage() {
    const { proposalId } = useParams();
    const { currentUser } = useAuth();
    const { data, isLoading, hasError } = useGet(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}?shouldGetFinancingOptions=${true}`);
    const proposal = !!data && data.proposal || {};
    const financingOptions = !!data && data.financingOptions || [];
    const initialState = {
        pricePerWatt: '',
        financingType: 'Cash',
        addersValue: '',
        addersNotes: '',
        addersUpdated: false
    }

    const [state, setState] = useState(initialState);

    const {
        pricePerWatt,
        financingType,
        addersValue,
        addersNotes
    } = state;

    useEffect(() => {
        const pricePerWatt = getPricePerWattFromLead(proposal);
        const financingType = getLoanTypeFromLead(proposal);
        const addersValue = getAddersValueFromLead(proposal);
        const addersNotes = getAddersNotesFromLead(proposal);
        if (!!pricePerWatt) {
            setState(prevState => ({
                ...prevState,
                pricePerWatt,
                financingType,
                addersValue,
                addersNotes
            }));
        }
    }, [data]);

    const handleChange = e => {
        if (e.target.name === "addersValue") {
            setState(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value,
                addersUpdated: true
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }
    }

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            console.log("handleSubmit");
            const data = {
                id: proposal._id,
                pricePerWatt: state.pricePerWatt,
                loanType: state.financingType,
                addersValue: state.addersValue,
                addersNotes: state.addersNotes,
                addersUpdated: state.addersUpdated
            }
            setState({
                ...state,
                isLoading: true
            });
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/api/leads`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        } catch (error) {
            console.log({ errorHandleSubmit: error });
            setState({
                ...state,
                hasError: true
            });
        }
    }

    const enableSubmit = true;

    if (hasError) {
        return (
            <Container showSettings responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading || state.isLoading) {
        return (
            <Container showSettings responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else {
        return (
            <Container proposalLogo showSettings responsive lead={proposal}>
                <Box m={2}>
                    <Typography variant="h3" mb={2}>Pricing</Typography>
                    <form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
                        <p className='mb-1 text-medium'>Financing Type</p>
                        <select
                            className='styled-input'
                            name="financingType"
                            id="financingType"
                            labelId="financingTypeLabel"
                            value={financingType}
                            onChange={handleChange}
                        >
                            {financingOptions.map((option, index) => (
                                <option key={`${index}-${option}`} value={option}>{option}</option>
                            ))}
                        </select>
                        <p className='mb-1 text-medium'>Price Per Watt ($)</p>
                        <input
                            className='styled-input'
                            onChange={handleChange}
                            id="pricePerWatt"
                            label="Price Per Watt ($)"
                            name="pricePerWatt"
                            value={pricePerWatt}
                            type="number"
                            variant="outlined"
                            placeholder="2.87"
                        />

                        <p className='mb-1 text-medium'>Adders Total Amount ($)</p>
                        <input
                            className='styled-input'
                            onChange={handleChange}
                            id="addersValue"
                            label="Adders Total Amount ($)"
                            name="addersValue"
                            value={addersValue}
                            type="number"
                            variant="outlined"
                        />
                        <p className='mb-1 text-medium'>Adders Notes</p>
                        <input
                            className='styled-input'
                            onChange={handleChange}
                            id="addersNotes"
                            label="Adders Notes"
                            multiline
                            rows={4}
                            name="addersNotes"
                            value={addersNotes}
                        />
                        <button className="button-default button-primary mt-1" type="submit" disabled={!enableSubmit}>Save</button>
                    </form>
                </Box >
            </Container >
        )
    }
}
