const { getDoesStringContainString, formatStringDollars, formatNumber } = require("../utils/strings");
const { getAnnualProductionFromSystem, getNumberOfPanelsFromSystem } = require("./system");
const ImageEnphase = require("../images/batteries/enphase-battery.jpg");
const ImageTesla = require("../images/batteries/tesla-battery.jpeg");
const ImageSolArk = require("../images/batteries/sol-ark-battery.jpg");
const ImageBatteriesInGarage = require("../images/batteries/garage-batteries.jpg");


function getBatteryCostFromLead(lead) {
    return lead?.system?.battery?.cost;
}

function getBatteryCapacityFromLead(lead) {
    return lead?.system?.battery?.capacity;
}

function getBatteryNameFromLead(lead) {
    return lead?.system?.battery?.name;
}

function getBatteryRebateAmountFromLead(lead) {
    const rebateAmount = lead?.financing?.batteryRebate;
    if (!!rebateAmount) {
        return formatStringDollars(rebateAmount);
    }
}

function getBatteryImageSrc(batteryName) {
    let batteryImageSrc = ImageBatteriesInGarage;
    const isEnphase = getDoesStringContainString(batteryName, "iq");
    const isTesla = getDoesStringContainString(batteryName, "tesla");
    const isSolArk = getDoesStringContainString(batteryName, "sol-ark");
    if (isEnphase) {
        batteryImageSrc = ImageEnphase;
    } else if (isTesla) {
        batteryImageSrc = ImageTesla;
    } else if (isSolArk) {
        batteryImageSrc = ImageSolArk;
    }
    return batteryImageSrc;
}

function getFirstNameFromLead(lead) {
    return lead?.firstName;
}

function getLastNameFromLead(lead) {
    return lead?.lastName;
}

function getFullNameFromLead(lead) {
    const firstName = !!lead && lead.firstName;
    const lastName = !!lead && lead.lastName;
    if (!!firstName && !!lastName) {
        return firstName + " " + lastName
    } else if (!!firstName) {
        return firstName;
    } else if (!!lastName) {
        return lastName;
    }
}

function getPhoneFromLead(lead) {
    if (!!lead && !!lead.phone && lead.phone.length > 0) {
        return lead.phone[0]
    }
}

function getEmailFromLead(lead) {
    if (!!lead && !!lead.email && lead.email.length > 0) {
        return lead.email[0]
    }
}

function getAddressFromLead(lead) {
    if (!!lead && !!lead.location) {
        return lead.location.address;
    }
}

function getElectricVehicleFromLead(lead) {
    return lead?.electricVehicle;
}

function getPricePerKwhFromLead(lead) {
    if (!!lead && !!lead.usage) {
        return lead.usage.pricePerKwh;
    }
}

function getPotentialSolarSavingsFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.potentialSavings);
    } else {
        return lead?.financing?.potentialSavings;
    }
}

function getPricePerWattFromLead(lead) {
    return lead?.financing?.inputs?.ppw;
}

function getAnnualUsageFromLead(lead) {
    return lead?.usage?.yearly;
}

function getOffsetFromLead(lead) {
    const production = getAnnualProductionFromSystem(lead?.system);
    const usage = getAnnualUsageFromLead(lead);
    if (!!production && !!usage) {
        return Math.round((production / usage) * 100);
    }
}

function getLoanTypeFromLead(lead) {
    return lead?.financing?.inputs?.loanType;
}


function getTotalSystemCostFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.systemCost);
    } else {
        return lead?.financing?.systemCost;
    }
}

function getTotalBatteryCostFromLead(lead, formatDollarValue = true) {
    const batteryCost = getBatteryCostFromLead(lead);
    if (formatDollarValue) {
        return formatStringDollars(batteryCost);
    } else {
        return batteryCost;
    }
}

function getTotalAddersFromLead(lead, formatDollarValue = true) {
    const adders = getAddersValueFromLead(lead);
    if (formatDollarValue) {
        return formatStringDollars(adders);
    } else {
        return adders;
    }
}

function getTotalSystemBatteryAddersCostFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.cashPriceNoTaxCredit);
    } else {
        return lead?.financing?.cashPriceNoTaxCredit;
    }
}

function getTaxCreditsFromLead(lead, formatDollarValue = true) {
    let taxCredits = "";
    const totalSystemCost = getTotalSystemBatteryAddersCostFromLead(lead, false);
    const systemCostAfterTaxCredits = getTotalSystemBatteryAddersCostAfterTaxCreditsFromLead(lead, false);
    if (!!totalSystemCost && !!systemCostAfterTaxCredits) {
        taxCredits = totalSystemCost - systemCostAfterTaxCredits;
    }
    if (!!formatDollarValue) {
        taxCredits = formatStringDollars(taxCredits);
    }
    return taxCredits;
}

function getTotalSystemBatteryAddersCostAfterTaxCreditsFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.cashPriceWithTaxCredit);
    } else {
        return lead?.financing?.cashPriceWithTaxCredit;
    }
}
function getMonthlyPaymentWithTaxCreditFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.monthlyPaymentWithTaxCredit);
    } else {
        return lead?.financing?.monthlyPaymentWithTaxCredit;
    }
}
function getMonthlyPaymentNoTaxCreditFromLead(lead, formatDollarValue = true) {
    if (formatDollarValue) {
        return formatStringDollars(lead?.financing?.monthlyPaymentNoTaxCredit);
    } else {
        return lead?.financing?.monthlyPaymentNoTaxCredit;
    }
}
function getLoanTermFromLead(lead) {
    return lead?.financing?.inputs?.term;
}
function getInterestRateFromLead(lead) {
    return lead?.financing?.inputs?.interestRate;
}

function getIsCashDealFromLead(lead) {
    const loanType = getLoanTypeFromLead(lead);
    if (!loanType || loanType === "Cash" || loanType === "cash") {
        return true;
    }
}

const getShouldRetryFetchProposal = (proposal) => {
    if (!!proposal) {
        const isSystemBatteryOnly = getIsSystemBatteryOnlyFromLead(proposal);
        const projectIdOpenSolar = proposal?.projectIdOpenSolar;
        const createdOn = proposal?.createdOn;
        const systemImage = proposal?.systemImagePathname;
        const systemSize = getNumberOfPanelsFromSystem(proposal?.system);
        const systemCost = getTotalSystemCostFromLead(proposal, false);
        console.log({
            isSystemBatteryOnly,
            systemImage,
            projectIdOpenSolar,
            systemSize
        });
        if (isSystemBatteryOnly) {
            return false;
        }
        if (!projectIdOpenSolar) {
            return false;
        }
        if (!!systemImage) {
            // if a rep creates a design but forgets to save and/or add panels (resulting in no system size or cost, don't delay loading the proposal
            //   show them the design image with no panels.
            return false;
        }
        if (!!systemSize && !!systemCost) {
            return false;
        }
        if (!!createdOn) {
            // if a proposal is old, we don't need to wait for the possibility that Open Solar data may be generated.
            const currentTime = new Date().getTime();
            const fiveMinutesInMilliseconds = 5 * 60 * 1000;
            if ((currentTime - new Date(createdOn).getTime()) > fiveMinutesInMilliseconds) {
                return false;
            }
        }
        return true;

    }
    return true;
}

function getIsSystemBatteryOnlyFromLead(lead) {
    if (!!lead?.wantsBattery && !lead?.wantsSolar) {
        return true;
    }
}

function getAddersValueFromLead(lead) {
    return lead?.adders?.value;
}


function getAddersNotesFromLead(lead) {
    return lead?.adders?.notes;
}

function getUtilityCompanyFromLead(lead) {
    return lead?.utility;
}

function getBillsFromLead(lead) {
    return lead?.files;
}

function getUsageInputsFromLead(lead) {
    console.log(lead);
    return {
        usageType: lead?.usage?.inputs?.type,
        usageYearly: lead?.usage?.inputs?.yearly,
        usageMonthly: lead?.usage?.inputs?.monthly,
        usageMonth: lead?.usage?.inputs?.month,
        billMonthly: lead?.usage?.inputs?.bill,
    }
}

function getGallonsOfGasFromProduction(annualProduction, shouldFormatNumber = true) {
    const epaGallonsOfGasPerKwh = 0.079;
    const years = 20;
    const gallons = Math.round((annualProduction || 0) * epaGallonsOfGasPerKwh * years);
    if (!!shouldFormatNumber) {
        return formatNumber(gallons);
    }
    return gallons;
}

function getTreesPlantedFromProduction(annualProduction, shouldFormatNumber = true) {
    const treesPlantedPerKwh = 0.012;
    const years = 20;
    const treesPlanted = Math.round((annualProduction || 0) * treesPlantedPerKwh * years);
    if (!!shouldFormatNumber) {
        return formatNumber(treesPlanted);
    }
    return treesPlanted;
}

module.exports = {
    getBatteryCostFromLead,
    getBatteryCapacityFromLead,
    getBatteryNameFromLead,
    getBatteryRebateAmountFromLead,
    getBatteryImageSrc,
    getFirstNameFromLead,
    getLastNameFromLead,
    getFullNameFromLead,
    getPhoneFromLead,
    getEmailFromLead,
    getAddressFromLead,
    getElectricVehicleFromLead,
    getPricePerKwhFromLead,
    getPotentialSolarSavingsFromLead,
    getPricePerWattFromLead,
    getOffsetFromLead,
    getLoanTypeFromLead,
    getTotalSystemCostFromLead,
    getTotalBatteryCostFromLead,
    getTotalAddersFromLead,
    getTotalSystemBatteryAddersCostFromLead,
    getTaxCreditsFromLead,
    getTotalSystemBatteryAddersCostAfterTaxCreditsFromLead,
    getMonthlyPaymentWithTaxCreditFromLead,
    getMonthlyPaymentNoTaxCreditFromLead,
    getLoanTermFromLead,
    getInterestRateFromLead,
    getIsCashDealFromLead,
    getAddersValueFromLead,
    getAddersNotesFromLead,
    getShouldRetryFetchProposal,
    getUtilityCompanyFromLead,
    getBillsFromLead,
    getUsageInputsFromLead,
    getGallonsOfGasFromProduction,
    getTreesPlantedFromProduction
}
