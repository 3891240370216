import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Container from './Container';
import useGet from '../hooks/useGet';
import LoadingIcon from '../components/LoadingIcon';
import { useAuth } from '../contexts/AuthContext';
import { getElectricVehicleFromLead, getEmailFromLead, getFirstNameFromLead, getLastNameFromLead, getPhoneFromLead } from '../services/leads';
import { formatPhone } from '../services/phone';

export default function BasicInfoPage() {
    const { proposalId } = useParams();
    const { currentUser } = useAuth();
    const { data, isLoading, hasError } = useGet(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}`);
    const proposal = !!data && data.proposal || {};
    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        electricVehicle: '',
        projectNotes: ''
    }
    console.log(initialState);
    const [state, setState] = useState(initialState);

    const {
        firstName,
        lastName,
        email,
        phone,
        electricVehicle,
        projectNotes
    } = state;

    useEffect(() => {
        const firstName = getFirstNameFromLead(proposal);
        const lastName = getLastNameFromLead(proposal);
        const email = getEmailFromLead(proposal);
        const phone = getPhoneFromLead(proposal);
        const electricVehicle = getElectricVehicleFromLead(proposal);
        const projectNotes = proposal?.projectNotes;
        if (firstName || lastName || email || phone || electricVehicle || projectNotes) {
            setState(prevState => ({
                ...prevState,
                firstName,
                lastName,
                email,
                phone,
                electricVehicle,
                projectNotes
            }));
        }
    }, [data]);


    const handleChange = e => {
        if (e.target.name === "phone") {
            let formattedPhone = formatPhone(e.target.value);
            setState({
                ...state,
                phone: formattedPhone
            });
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            });
        }
    }

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            console.log("handleSubmit");
            const data = {
                id: proposal._id,
                firstName: state.firstName,
                lastName: state.lastName,
                phone: state.phone,
                email: state.email,
                electricVehicle: state.electricVehicle,
                projectNotes: state.projectNotes
            }
            console.log(data);
            setState({
                ...state,
                isLoading: true
            });
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/api/leads`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        } catch (error) {
            console.log({ errorHandleSubmit: error });
            setState({
                ...state,
                hasError: true
            });
        }
    }

    const enableSubmit = true;

    if (hasError) {
        return (
            <Container showSettings responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading || state.isLoading) {
        return (
            <Container showSettings responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else {
        return (
            <Container proposalLogo showSettings responsive lead={proposal}>
                <form onSubmit={handleSubmit}>
                    <p className='mt-4 mb-1 text-medium'>First Name</p>
                    <input
                        className='styled-input'
                        name="firstName"
                        id="firstName"
                        placeholder="Enter first name"
                        type="text"
                        value={firstName}
                        onChange={handleChange}
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Last Name</p>
                    <input
                        className='styled-input'
                        name="lastName"
                        id="lastName"
                        placeholder="Enter last name"
                        type="text"
                        value={lastName}
                        onChange={handleChange}
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Email</p>
                    <input
                        className='styled-input'
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        type="email"
                        value={email}
                        onChange={handleChange}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Phone</p>
                    <input
                        className='styled-input'
                        label="Phone"
                        name="phone"
                        id="phone"
                        placeholder="Enter phone"
                        type="tel"
                        value={phone}
                        onChange={handleChange}
                        mb
                        required
                    />
                    <p className='mb-1 text-medium'>Project Notes</p>
                    <textarea
                        className='styled-input inter'
                        value={projectNotes}
                        name="projectNotes"
                        placeholder="Project Notes"
                        type="textarea"
                        onChange={handleChange}
                        rows="5"
                    />
                    {/* <p className='mb-1 text-medium'>Electric Vehicle</p>
                    <input
                        className='styled-input'
                        value={electricVehicle}
                        name="electricVehicle"
                        placeholder="Electric Vehicle"
                        type="text"
                        onChange={handleChange}
                    /> */}
                    <button className="button-default button-primary" type="submit" disabled={!enableSubmit}>Update</button>
                </form>
            </Container>
        )
    }
}
