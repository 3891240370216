import * as React from 'react';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import { CircularProgress, IconButton, ListItem, ListItemIcon, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Check } from '@mui/icons-material';
import { generateCoperniqRecordLinkFromId } from '../services/coperniq';
import Version from './Version';

export default function ProposalMenu(props) {
    const { currentUser } = useAuth();
    const initialState = {
        isLoadingRequest: false,
        isRequestSuccess: false
    }
    const [state, setState] = React.useState(initialState);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const { logout } = useAuth();
    const navigate = useNavigate();
    const { proposalId } = useParams();
    const coperniqId = props?.lead?.coperniqId;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const handleEditDesign = (event) => {
        console.log("handleEditDesign");
        handleClose(event);
        navigate(`/${proposalId}/system-designs`);
    }

    const handleRegenerateProposal = async (event) => {
        try {
            console.log("handleRegenerateProposal");
            setState(prevState => ({
                ...prevState,
                isLoadingRequest: true
            }));
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/api/leads?action=sync&proposalId=${proposalId}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoadingRequest: false,
                isRequestSuccess: true
            }));

            setTimeout(() => {
                handleClose(event);
                setState(prevState => ({
                    ...prevState,
                    isRequestSuccess: false
                }));
                window.scrollTo(0, 0);
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.log({ errorHandleRegenerateProposal: error });
            handleClose(event);
            setState(prevState => ({
                ...prevState,
                isLoadingRequest: false,
                isRequestSuccess: false
            }));
            Sentry.captureException(error);
        }
    }

    const handleUpdateBattery = (event) => {
        console.log("handleUpdateBattery");
        handleClose(event);
        navigate(`/${proposalId}/battery`);
    }

    const handleNavigate = (event, route) => {
        console.log("handleNavigate");
        handleClose(event);
        navigate(route);
    }

    const handleViewInCoperniq = (event) => {
        console.log("handleViewInCoperniq");
        handleClose(event);
        const coperniqLink = generateCoperniqRecordLinkFromId(coperniqId);
        if (!!coperniqLink) {
            window.open(coperniqLink);
        }
    }

    const handleLogout = (event) => {
        console.log("handleLogout");
        handleClose(event);
        logout().then(() => {
            navigate("/");
        })
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const {
        isLoadingRequest,
        isRequestSuccess
    } = state;

    if (!currentUser) {
        // only show hamburger menu to logged-in users
        return <></>
    }

    let iconStyles = {}

    if (!!props.color) {
        iconStyles["color"] = props.color;
    }

    return (
        <Stack direction="row" spacing={2}>
            <div>
                <IconButton
                    size="large"
                    edge="start"
                    color="secondary"
                    aria-label="menu"
                    sx={iconStyles}
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="top-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'right top'
                            }}
                        >
                            <Paper sx={{ zIndex: "10" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={handleEditDesign}>Edit Design</MenuItem>
                                        {!!isLoadingRequest && <MenuItem disabled={true}>
                                            <CircularProgress color="primary" />
                                            <ListItemIcon></ListItemIcon>
                                            <Typography variant="inherit">Regenerate Proposal</Typography>
                                        </MenuItem>}
                                        {!!isRequestSuccess && <MenuItem disabled={true}>
                                            <ListItemIcon>
                                                <Check />
                                            </ListItemIcon>
                                            <Typography variant="inherit">Success</Typography>
                                        </MenuItem>}
                                        {(!isLoadingRequest && !isRequestSuccess) && <MenuItem onClick={handleRegenerateProposal}>Regenerate Proposal</MenuItem>}
                                        <MenuItem onClick={e => handleNavigate(e, `/${proposalId}/info`)}>Basic Info</MenuItem>
                                        <MenuItem onClick={handleUpdateBattery}>Battery</MenuItem>
                                        <MenuItem onClick={e => handleNavigate(e, `/${proposalId}/usage`)}>Usage</MenuItem>
                                        <MenuItem onClick={e => handleNavigate(e, `/${proposalId}/pricing`)}>Pricing</MenuItem>
                                        <MenuItem onClick={handleViewInCoperniq} disabled={!coperniqId}>View in Coperniq</MenuItem>
                                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                        <ListItem click><Version /></ListItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
}
