import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Container from './Container';
import Version from '../components/Version';

export default function Signup() {
    const { logout } = useAuth();
    const navigate = useNavigate();

    function handleRedirect(e, route) {
        e.preventDefault();
        navigate(route);
    }

    function handleLogout(e) {
        console.log("handleLogout");
        e.preventDefault();
        logout();
        navigate("/");
    }

    return (
        <Container responsive>
            <h3>Settings</h3>
            <button
                className='block'
                onClick={e => handleRedirect(e, '/forgot-password')}
            >
                Forgot password?
            </button>
            <button
                className='block'
                onClick={handleLogout}
            >
                Sign out
            </button>
            <Version />
        </Container>
    )
}
