import React, { useRef } from 'react'

export default function StyledInput(props) {
    const inputRef = useRef(null);
    const {
        label,
        placeholder,
        name,
        id,
        value,
        onChange,
        mb,
        units,
        type,
        required
    } = props;
    let classesInput = "styled-input";
    if (!!mb) {
        classesInput += " mb-1"
    }
    if(type === "dropdown") {
        classesInput += " pointer";
        if(!value) {
            classesInput += " grey";
        }
    }
    let showUnit = false;
    if (!!units) {
        if (!!value) {
            classesInput += " pr-4-5"
            showUnit = true;
            console.log("show unit");
        }
    }
    if (type === "dropdown") {
        return (
            <>
                <label
                    id={`label-${id}`}
                >
                    {label}
                </label>
                <select
                    ref={inputRef}
                    className={classesInput}
                    placeholder={placeholder}
                    id={id}
                    htmlFor={`label-${id}`}
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {props.children}
                </select>
            </>
        )
    } else {
        return (
            <>
                <label
                    id={`label-${id}`}
                >
                    {label}
                </label>
                <input
                    required={required}
                    ref={inputRef}
                    className={classesInput}
                    placeholder={placeholder}
                    id={id}
                    htmlFor={`label-${id}`}
                    name={name}
                    value={value}
                    onChange={onChange}
                    type={type || "text"}
                />
                {showUnit && <span className="ml-neg-4 flex-center">{units}</span>}
            </>
        )
    }
}
