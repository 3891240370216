import { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import axios from 'axios';

const useVersion = () => {
    console.log("useVersion");

    const initialState = {
        version: null,
        isLoading: true,
        hasError: false
    }
    const [state, setState] = useState(initialState);
    const {
        version,
        isLoading,
        hasError
    } = state;

    const getVersion = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/public/version`,
                { 'Content-Type': 'application/json' }
            );
            setState(prevState => ({
                ...prevState,
                version: res.data?.version,
                isLoading: false,
            }));
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                hasError: true
            }));
        }
    };

    async function handleVersionUpdate() {
        console.log("handleVersionUpdate");
        const versionInLocalStorage = localStorage.getItem('version');
        const versionFromBackend = state.version;
        console.log({ versionInLocalStorage, versionFromBackend });
        if (!!versionFromBackend && (versionInLocalStorage !== versionFromBackend)) {
            console.log("checking should clear cache");
            localStorage.setItem('version', state.version);
            if (!!versionInLocalStorage) {
                // only clear cache if there is a version in local storage
                //  we don't want to clear the cache if we're just getting the version from the backend for the first time
                //  (i.e. after clearing the cache, which would create a loop)
                console.log("should clear cache");
                window.location.reload(true);
            }
        } else {
            console.log("not clearing cache");
        }
    }

    useEffect(() => {
        try {
            getVersion();
        } catch (error) {
            console.log({ erroruseVersion: error });
            Sentry.captureException(error);
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                hasError: true
            }));
        }
    }, []);

    useEffect(() => {
        if (!!state.version) {
            handleVersionUpdate();
        }
    }, [state.version]);

    return { version, isLoading, hasError };
};

export default useVersion;
