function generateArrayOfValuesFromMonths(months) {
    const january = !!months && months["january"]
    const february = !!months && months["february"]
    const march = !!months && months["march"]
    const april = !!months && months["april"]
    const may = !!months && months["may"]
    const june = !!months && months["june"]
    const july = !!months && months["july"]
    const august = !!months && months["august"]
    const september = !!months && months["september"]
    const october = !!months && months["october"]
    const november = !!months && months["november"]
    const december = !!months && months["december"]
    return [
        january || 0,
        february || 0,
        march || 0,
        april || 0,
        may || 0,
        june || 0,
        july || 0,
        august || 0,
        september || 0,
        october || 0,
        november || 0,
        december || 0
    ];
}

function getMaxValueFromTwoArrays(array1 = [], array2 = []) {
    console.log("getMaxValueFromTwoArrays");
    if (!!array1 && !!array2) {
        const maxValue = Math.max(...array1, ...array2);
        if (!!maxValue) {
            return Number(maxValue);
        }
    }
}

function getMaxValueFromChartJsDatasets(datasets) {
    const mainArray = [];
    if (!!datasets && datasets.length > 0) {
        datasets.forEach(set => {
            if (!!set && !!set.data && set.data.length > 0) {
                mainArray.push(...set.data);
            }
        })
    }
    console.log(mainArray);
    if (mainArray.length > 0) {
        return Math.max(...mainArray);
    }
}


function updateMatchingRecordInArray(array, name, value, update) {
    const newArray = [];
    if (!!array && array.length > 0) {
        array.forEach(item => {
            if (!!item[name] && item[name] === value) {
                newArray.push({
                    ...item,
                    ...update
                })
            } else {
                newArray.push({ ...item })
            }
        });
    }
    return newArray;
}

function removeMatchingRecordFromArray(array, name, value, update) {
    const newArray = [];
    if (!!array && array.length > 0) {
        array.forEach(item => {
            if (!!item[name] && item[name] === value) {
                console.log("not adding matching element to new array");
            } else {
                newArray.push({ ...item })
            }
        });
    }
    return newArray;
}


module.exports = {
    generateArrayOfValuesFromMonths,
    getMaxValueFromTwoArrays,
    getMaxValueFromChartJsDatasets,
    updateMatchingRecordInArray,
    removeMatchingRecordFromArray
}
