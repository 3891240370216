import React from 'react'
import { useNavigate } from 'react-router-dom';
import TreepublicTitleImage from '../images/treepublic_logo_large.png';

export default function TreepublicLogoClickable(props) {
    const { route } = props;
    const navigate = useNavigate();
    const handleNavigate = e => {
        e.preventDefault();
        navigate(route);
    }
    return (
        <img onClick={handleNavigate} className='mb-1 image-small pointer' styles={{ display: 'block' }} src={TreepublicTitleImage} alt="main title Treepublic" />
    )
}
