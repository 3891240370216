import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

export default function PieChartProductionVsConsumption(props) {
    const {
        usage,
        system,
        fontSize
    } = props;

    const yearlyUsage = usage?.yearly;
    const yearlyProduction = system?.yearly;

    const usageNotCoveredBySolar = yearlyUsage - yearlyProduction;
    let yearlyUsagePercentage = ((usageNotCoveredBySolar / yearlyUsage) * 100).toFixed(2);
    const yearlyProductionPercentage = (100 - yearlyUsagePercentage).toFixed(2);

    const options = {
        responsive: true,
        title: {
            display: true,
            text: 'Home Energy Consumption vs Estimated Solar Production'
        },
        plugins: {
            datalabels: {
                display: true,
                color: '#fff',
                font: {
                    weight: 'bold',
                    size: fontSize || 20
                },
                anchor: 'center',
                formatter: function (value, context) {
                    return value + '%';
                }
            },
            legend: {
                display: false
            },
        }
    }

    const data = {
        labels: ['Solar Production', 'Home Energy Consumption'],
        datasets: [{
            data: [yearlyProductionPercentage, yearlyUsagePercentage],
            backgroundColor: ['rgba(147, 178, 166, 1)', 'rgba(201, 188, 112, 1)']
        }]
    };

    return (
        <Pie
            options={options}
            data={data}
        />
    )
}
