import { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const useGet = (url, includeFirebaseAuth = false) => {
    console.log("useGet");
    const { currentUser } = useAuth();

    const initialState = {
        data: null,
        isLoading: true,
        hasError: false
    }
    const [state, setState] = useState(initialState);
    const {
        data,
        isLoading,
        hasError
    } = state;

    useEffect(() => {
        try {
            const getData = async () => {
                try {
                    const headers = {
                        'Content-Type': 'application/json'
                    };
                    // to do - possibly make currentUser optional
                    if (includeFirebaseAuth && currentUser) {
                        const tokenIdResult = await currentUser.getIdTokenResult();
                        const newToken = tokenIdResult?.token;
                        headers['Authorization'] = `Bearer ${newToken}`;
                    }
                    const res = await axios.get(url, { headers });
                    setState(prevState => ({
                        ...prevState,
                        data: res.data,
                        isLoading: false,
                    }));
                } catch (error) {
                    setState(prevState => ({
                        ...prevState,
                        isLoading: false,
                        hasError: true
                    }));
                }
            };

            getData();
        } catch (error) {
            console.log({ errorUseGet: error });
            Sentry.captureException(error);
            setState(prevState => ({
                ...prevState,
                isLoading: false,
                hasError: true
            }));
        }
    }, [url]);

    return { data, isLoading, hasError };
};

export default useGet;
