import React from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LoopIcon from '@mui/icons-material/Loop';
import ErrorIcon from '@mui/icons-material/Error';

export default function FileContainer(props) {
    const {
        bill,
        hideRemove,
        handleRemove,
        shortenName
    } = props;
    const classes = props.classes || 'p-2 flex-between';
    let googleDriveId = '';
    let name = '';
    let isLoading = false;
    let error;
    if (!!bill) {
        googleDriveId = bill.googleDriveId;
        name = bill.name;
        isLoading = bill.isLoading;
        error = bill.error;
    }
    if (!!shortenName) {
        if (!!name && typeof name === "string") {
            if (name.length >= 12) {
                name = name.slice(0, 9) + "..." + name.slice(name.length - 6, name.length);
            }
        }
    }

    if (!!bill && !!bill.deleted) {
        return <></>;
    } else if (error) {
        return (
            <p className={classes}>
                <span className='flex-center'><ErrorIcon /><span className="ml-1">{name}</span></span>
                <p>Please try again or contact support.</p>
            </p>
        )
    } else if (isLoading) {
        return (
            <p className={classes}>
                <span className='flex-center'><LoopIcon /><span className="ml-1">{name}</span></span>
            </p>
        )
    } else {
        return (
            <p className={classes}>
                {error && <span>ERROR</span>}
                {isLoading && <span>LOADING</span>}
                <span className='flex-center'><FilePresentIcon />{name}</span>
                {!hideRemove && <a className="link" onClick={e => handleRemove(e, googleDriveId)}>Remove</a>}
            </p>
        )
    }
}
