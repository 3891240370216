import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Container from './Container';
import useGet from '../hooks/useGet';
import LoadingIcon from '../components/LoadingIcon';
import StyledInput from '../components/StyledInput';
import { useAuth } from '../contexts/AuthContext';
import { getPricePerKwhFromLead, getUtilityCompanyFromLead, getBillsFromLead, getUsageInputsFromLead } from '../services/leads';
import Usage from '../components/Usage';
import UploadFiles from '../components/UploadFiles';
import utilities from '../data/utilities.json';
import { getPreviousMonth } from '../services/time';
import { removeMatchingRecordFromArray, updateMatchingRecordInArray } from '../services/arrays';

export default function UsagePage() {
    const { proposalId } = useParams();
    const { currentUser } = useAuth();
    const { data, isLoading, hasError } = useGet(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}`);
    const proposal = !!data && data.proposal || {};
    const previousMonth = getPreviousMonth();
    const initialState = {
        bills: [],
        pricePerKwh: '',
        utilityCompany: '',
        usageType: 'Yearly Usage',
        usageYearly: '',
        usageMonthly: '',
        usageMonth: previousMonth,
        billMonthly: ''
    }
    const [state, setState] = useState(initialState);

    const {
        pricePerKwh,
        utilityCompany,
        bills,
        usageType,
        usageYearly,
        usageMonthly,
        usageMonth,
        billMonthly,
        utilityCompanyOther
    } = state;

    useEffect(() => {
        const pricePerKwh = getPricePerKwhFromLead(proposal);
        const utilityCompany = getUtilityCompanyFromLead(proposal);
        const bills = getBillsFromLead(proposal);
        const { usageType, usageYearly, usageMonthly, usageMonth, billMonthly } = getUsageInputsFromLead(proposal);
        if (!!pricePerKwh || utilityCompany || utilityCompanyOther || (bills?.length > 0) || usageType || usageYearly || usageMonthly || usageMonth || billMonthly) {
            setState(prevState => ({
                ...prevState,
                id: proposal?._id,
                pricePerKwh,
                utilityCompany,
                utilityCompanyOther,
                bills,
                usageType,
                usageYearly,
                usageMonthly,
                usageMonth,
                billMonthly
            }));
        }
    }, [data]);

    const handleChange = e => {

        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleToggle = e => {
        setState({
            ...state,
            [e.target.name]: !state[e.target.name]
        })
    }

    const handleUploadBill = (file) => {
        if (!!file) {
            const temporaryId = file.temporaryId;
            setState(prevState => ({
                ...prevState,
                bills: [
                    ...prevState.bills,
                    file
                ]
            }));

            currentUser.getIdTokenResult().then(tokenIdResult => {
                const newToken = !!tokenIdResult && tokenIdResult.token;

                const formData = new FormData();
                formData.append("bill", file.file);
                return axios.post(`${process.env.REACT_APP_API_URL}/api/leads/${state.id || 0}/bills`, formData, {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    if (!!res && !!res.data) {
                        setState(prevState => {
                            const bills = prevState.bills;
                            const update = {
                                isLoading: false
                            }
                            if (!!res.data.googleDriveId) {
                                update['googleDriveId'] = res.data.googleDriveId;
                            }
                            const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, update);
                            return ({
                                ...prevState,
                                bills: updatedBills
                            });
                        });
                    } else {
                        setState(prevState => {
                            const bills = prevState.bills;
                            const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                            return ({
                                ...prevState,
                                bills: updatedBills
                            });
                        })
                    }
                }).catch(error => {
                    console.log("error: ");
                    console.log(error);
                    Sentry.captureException(error);
                    setState(prevState => {
                        const bills = prevState.bills;
                        const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                        return ({
                            ...prevState,
                            bills: updatedBills
                        });
                    })
                });
            });
        }
    }

    const handleRemoveBill = (googleDriveId) => {
        if (!!googleDriveId) {
            setState(prevState => {
                const bills = prevState.bills;
                const update = {
                    isLoading: true
                }
                const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, update);
                return ({
                    ...prevState,
                    bills: updatedBills
                });
            });
            currentUser.getIdTokenResult().then(tokenIdResult => {
                const newToken = !!tokenIdResult && tokenIdResult.token;
                return axios.delete(`${process.env.REACT_APP_API_URL}/api/leads/${state.id}/bills/${googleDriveId}`, {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    console.log("file deleted!");
                    if (!!res && !!res.data && !!res.data.success) {
                        setState(prevState => {
                            const bills = prevState.bills;
                            const updatedBills = removeMatchingRecordFromArray(bills, 'googleDriveId', googleDriveId);
                            return ({
                                ...prevState,
                                bills: updatedBills
                            });
                        });
                    } else {
                        throw Error("Attempt to delete file resulted in error");
                    }
                });
            }).catch(error => {
                console.log("error: ");
                console.log(error);
                Sentry.captureException(error);
                setState(prevState => {
                    const bills = prevState.bills;
                    const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, { error: true });
                    return ({
                        ...prevState,
                        bills: updatedBills
                    });
                })
            });
        }
    }

    const handleUpdateState = (data) => {
        if (!!data && typeof data === "object") {
            setState(prevState => ({
                ...prevState,
                ...data
            }));
        }
    }

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            console.log("handleSubmit");
            let utilityCompany = state.utilityCompany;
            if (utilityCompany === "Other") {
                utilityCompany = state.utilityCompanyOther;
            }
            const data = {
                id: proposal._id,
                pricePerKwh: state.pricePerKwh,
                utility: utilityCompany,
                usage: {
                    type: state.usageType,
                    yearly: state.usageYearly,
                    monthly: state.usageMonthly,
                    month: state.usageMonth,
                    bill: state.billMonthly
                }
            }
            setState({
                ...state,
                isLoading: true
            });
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/api/leads`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        } catch (error) {
            console.log({ errorHandleSubmit: error });
            setState({
                ...state,
                hasError: true
            });
        }
    }

    const enableSubmit = true;

    if (hasError) {
        return (
            <Container showSettings responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading || state.isLoading) {
        return (
            <Container showSettings responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else {
        return (
            <Container proposalLogo showSettings responsive lead={proposal}>
                <p className='mt-4 mb-4 text-medium bold'>Usage</p>
                <form
                    className="p-1"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <p className="mb-1 text-medium">Utility Company</p>
                        <select
                            className='styled-input'
                            name="utilityCompany"
                            id="utilityCompany"
                            value={utilityCompany}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select Utility Company</option>
                            {utilities.map((name, index) => (
                                <option key={`utility-${index}-${name}`} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>
                        {utilityCompany === "Other" && (
                            <>
                                <p className="mb-1 text-medium">Input Utility Name</p>
                                <input
                                    className='styled-input'
                                    name="utilityCompanyOther"
                                    id="utilityCompanyOther"
                                    placeholder="Input Utility Name"
                                    type="text"
                                    value={utilityCompanyOther}
                                    onChange={handleChange}
                                    mb
                                    required
                                />
                            </>
                        )}
                    </div>
                    <div className='mt-2 mb-4'>
                        <UploadFiles
                            bills={bills}
                            handleToggle={handleToggle}
                            handleUploadBill={handleUploadBill}
                            handleRemoveBill={handleRemoveBill}
                        />
                    </div>
                    <Usage
                        handleUpdateState={handleUpdateState}
                        usageType={usageType}
                        usageYearly={usageYearly}
                        usageMonthly={usageMonthly}
                        usageMonth={usageMonth}
                        billMonthly={billMonthly}
                    />
                    <p className='mb-2 text-medium'>Price per kWh ($)</p>
                    <StyledInput
                        name="pricePerKwh"
                        id="pricePerKwh"
                        placeholder="$0.30"
                        type="number"
                        value={pricePerKwh}
                        onChange={handleChange}
                        mb
                    />
                    <button className="button-default button-primary" type="submit" disabled={!enableSubmit}>Save</button>
                </form>
            </Container>
        )
    }
}
