function getValueFromGoogleMapsAddress(name, components) {
    let value = '';
    const key = getGoogleMapsKeyFromName(name);
    if (!!key) {
        if (!!components && components.length > 0) {
            components.forEach(item => {
                if (!!item && !!item.types && item.types.length > 0) {
                    if (item.types.includes(key)) {
                        value = item.long_name || item.short_name;
                        return;
                    }
                }
            })
        }
    }

    return value;
}

function getGoogleMapsKeyFromName(name) {
    if (!!name) {
        if (name === "zip") {
            return "postal_code";
        } else if (name === "street") {
            return "route";
        } else if (name === "street number") {
            return "street_number";
        } else if (name === "city") {
            return "locality";
        } else if (name === "country") {
            return "country";
        } else if (name === "state") {
            return "administrative_area_level_1";
        }
    }
}

function concatenateStreetNumberAndStreet(streetNumber, street) {
    console.log({streetNumber, street});
    return [streetNumber, street].filter(Boolean).join(" ");
}

module.exports = {
    getValueFromGoogleMapsAddress,
    concatenateStreetNumberAndStreet
}
