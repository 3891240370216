import React from 'react';
import Container from './Container';

export default function PublicDesignPage() {
    const openSolarIframeSrc = "https://app.opensolar.com/#/projects/5226473/info";


    return (
        <Container>
            <iframe
                title="Open Solar"
                style={{ width: '100%', minHeight: '90vh' }}
                src={openSolarIframeSrc}
            />
        </Container>
    )
}
