import { useState } from "react";
import axios from 'axios';
import { useAuth } from "../contexts/AuthContext";

const useProposalForm = () => {
    const auth = useAuth();
    const currentUser = auth?.currentUser;

    const initialState = {
        isLoadingSubmission: false,
        hasErrorSubmission: false,
        openSolarIframeSrc: '',
        id: '',
        proposalId: ''
    }
    const [state, setState] = useState(initialState);

    const submitSyncSystemDesign = async (proposalId) => {
        console.log("submitSyncSystemDesign");
        // sync system design as lead update, with progressiveRetries of 10
        // the backend will start querying for system design information, behind the scenes
        const tokenIdResult = await currentUser?.getIdTokenResult();
        const newToken = !!tokenIdResult && tokenIdResult.token;
        return axios.patch(
            `${process.env.REACT_APP_API_URL}/api/leads?action=sync&proposalId=${proposalId}&progressiveRetries=${10}`,
            {},
            {
                headers: {
                    'Authorization': `Bearer ` + newToken,
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    const submitInitialForm = async (providedData, onSuccess) => {
        try {
            console.log("submitInitialForm");
            const tokenIdResult = await currentUser?.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const data = {
                address: providedData?.address,
                street: providedData?.street,
                city: providedData?.city,
                state: providedData?.state,
                country: providedData?.country,
                zipCode: providedData?.zipCode,
                lat: providedData?.lat,
                lng: providedData?.lng
            }
            setState(prevState => ({
                ...prevState,
                isLoadingSubmission: true
            }));
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/leads`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoadingSubmission: false,
                openSolarIframeSrc: res?.data?.openSolarIframeSrc,
                id: res?.data?.lead?._id,
                proposalId: res?.data?.lead?.proposalId
            }));
            onSuccess();
        } catch (error) {
            console.log({ errorHandleSubmit: error });
            setState({
                ...state,
                hasErrorSubmission: true
            });
        }

    }

    const submitFinalForm = async (providedData, onSuccess) => {
        console.log("submitFinalForm");
        // sync system design as lead update, with progressiveRetries of 10
        // the backend will start querying for system design information, behind the scenes
        const tokenIdResult = await currentUser?.getIdTokenResult();
        const newToken = !!tokenIdResult && tokenIdResult.token;
        let utilityCompany = providedData.utilityCompany;
        if (utilityCompany === "Other") {
            utilityCompany = providedData.utilityCompanyOther;
        }
        const data = {
            id: providedData.id,
            firstName: providedData.firstName,
            lastName: providedData.lastName,
            phone: providedData.phone,
            email: providedData.email,
            roofType: providedData.roofType,
            wantsSolar: providedData.wantsSolar,
            wantsBattery: providedData.wantsBattery,
            batteryName: providedData.batteryName,
            batteryCost: providedData.batteryCost,
            batteryCapacity: providedData.batteryCapacity,
            usage: {
                type: providedData.usageType,
                yearly: providedData.usageYearly,
                monthly: providedData.usageMonthly,
                month: providedData.usageMonth,
                bill: providedData.billMonthly
            },
            electricVehicle: providedData.electricVehicle,
            projectNotes: providedData.projectNotes,
            utility: utilityCompany,
            proposalFormReceived: true
        }
        setState(prevState => ({
            ...prevState,
            isLoadingSubmission: true
        }));
        const result = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/leads`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ` + newToken,
                    'Content-Type': 'application/json'
                }
            }
        )
        const proposalId = !!result && !!result.data && !!result.data.lead && result.data.lead.proposalId;
        setState(prevState => ({
            ...prevState,
            isLoadingSubmission: false,
            proposalId
        }));
        onSuccess();
    }

    return {
        isLoadingSubmission: state.isLoadingSubmission,
        hasErrorSubmission: state.hasErrorSubmission,
        openSolarIframeSrc: state.openSolarIframeSrc,
        id: state.id,
        proposalId: state.proposalId,
        submitSyncSystemDesign,
        submitInitialForm,
        submitFinalForm
    }
}

export default useProposalForm;
