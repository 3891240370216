import React from 'react';

import ImageRoofAsphaltShingle from '../images/roof/roof_asphalt_shingle.jpg';
import ImageRoofClayTile from '../images/roof/roof_clay_tile.png';
import ImageRoofFlatConcreteTile from '../images/roof/roof_concrete_tile_flat.jpg';
import ImageRoofConcreteTile from '../images/roof/roof_concrete_tile.jpg';
import ImageRoofMetal from '../images/roof/roof_metal.png';
import ImageGroundMount from '../images/roof/ground_mount_malibu_cropped.png';
import ImageCarport from '../images/roof/carport.jpg';
import ImageRoofFlatBeverlyHills from '../images/roof/roof_flat_beverly_hills.png';
import ImageRoofFlatGravel from '../images/roof/roof_flat_gravel.jpg';

export const getRoofImageSrc = (roofType) => {
    switch (roofType) {
        case 'Asphalt Shingle':
            return ImageRoofAsphaltShingle;
        case 'Clay Tile':
            return ImageRoofClayTile;
        case 'W- Concrete Tile':
            return ImageRoofConcreteTile;
        case 'Flat Concrete Tile':
            return ImageRoofFlatConcreteTile;
        case 'S- Concrete Tile':
            return ImageRoofConcreteTile;
        case 'Flat Roof - Asphalt':
            return ImageRoofFlatBeverlyHills;
        case 'Flat Roof - TPO':
            return ImageRoofFlatBeverlyHills;
        case 'Flat Roof - Gravel':
            return ImageRoofFlatGravel;
        case 'Metal - Standing Seam':
            return ImageRoofMetal;
        case 'Metal - Corrugated':
            return ImageRoofMetal;
        case 'Ground Mount':
            return ImageGroundMount;
        case 'Carport':
            return ImageCarport;
        default:
            return ImageRoofFlatBeverlyHills;
    }
};

export default function SystemImage(props) {
    const { roofType } = props;
    const imageSrc = getRoofImageSrc(roofType);


    return (
        <img className="image-proposal" src={imageSrc} alt="roof with solar" />
    );
}