import React, { useEffect, useRef, useState } from 'react';
import * as uuid from 'uuid';
import * as Sentry from "@sentry/react";
import axios from 'axios';
import FileContainer from './FileContainer';
import { useAuth } from '../contexts/AuthContext';
import { removeMatchingRecordFromArray, updateMatchingRecordInArray } from '../services/arrays';

export default function UploadFiles(props) {
    const { handleUpdateState } = props;
    const { currentUser } = useAuth();
    const fileInputRef = useRef(null);

    const initialState = {
        isLoading: false,
        bills: props.bills
    }

    const [state, setState] = useState(initialState);

    const handleButtonClickFileUpload = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    useEffect(() => {
        handleUpdateState({ bills })
    }, [state.bills]);

    async function handleUploadBill(e) {
        const fileFromEvent = !!e.target.files && e.target.files.length > 0 && !!e.target.files[0] && e.target.files[0];
        if (!!fileFromEvent) {
            const file = {
                file: fileFromEvent,
                isLoading: true,
                name: fileFromEvent.name,
                temporaryId: uuid.v4()
            }
            const temporaryId = file.temporaryId;
            setState(prevState => ({
                ...prevState,
                bills: [
                    ...prevState.bills,
                    file
                ]
            }));

            const tokenIdResult = await currentUser.getIdTokenResult();

            const newToken = !!tokenIdResult && tokenIdResult.token;

            const formData = new FormData();
            formData.append("bill", file.file);
            return axios.post(`${process.env.REACT_APP_API_URL}/api/leads/${state.id || 0}/bills`, formData, {
                headers: {
                    'Authorization': `Bearer ` + newToken,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (!!res && !!res.data) {
                    setState(prevState => {
                        const bills = prevState.bills;
                        const update = {
                            isLoading: false
                        }
                        if (!!res.data.googleDriveId) {
                            update['googleDriveId'] = res.data.googleDriveId;
                        }
                        const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, update);
                        return ({
                            ...prevState,
                            bills: updatedBills
                        });
                    });
                } else {
                    setState(prevState => {
                        const bills = prevState.bills;
                        const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                        return ({
                            ...prevState,
                            bills: updatedBills
                        });
                    })
                }
            }).catch(error => {
                console.log("error: ");
                console.log(error);
                Sentry.captureException(error);
                setState(prevState => {
                    const bills = prevState.bills;
                    const updatedBills = updateMatchingRecordInArray(bills, 'temporaryId', temporaryId, { error: true });
                    return ({
                        ...prevState,
                        bills: updatedBills
                    });
                })
            });
        }
    }

    async function handleRemoveBill(e, googleDriveId) {
        e.preventDefault();
        if (!!googleDriveId) {
            setState(prevState => {
                const bills = prevState.bills;
                const update = {
                    isLoading: true
                }
                const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, update);
                return ({
                    ...prevState,
                    bills: updatedBills
                });
            });
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            return axios.delete(`${process.env.REACT_APP_API_URL}/api/leads/${state.id}/bills/${googleDriveId}`, {
                headers: {
                    'Authorization': `Bearer ` + newToken,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                console.log("file deleted!");
                if (!!res && !!res.data && !!res.data.success) {
                    setState(prevState => {
                        const bills = prevState.bills;
                        const updatedBills = removeMatchingRecordFromArray(bills, 'googleDriveId', googleDriveId);
                        return ({
                            ...prevState,
                            bills: updatedBills
                        });
                    });
                } else {
                    throw Error("Attempt to delete file resulted in error");
                }
            }).catch(error => {
                console.log("error: ");
                console.log(error);
                Sentry.captureException(error);
                setState(prevState => {
                    const bills = prevState.bills;
                    const updatedBills = updateMatchingRecordInArray(bills, 'googleDriveId', googleDriveId, { error: true });
                    return ({
                        ...prevState,
                        bills: updatedBills
                    });
                })
            });
        }
    }

    const {
        bills
    } = state;

    return (
        <>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleUploadBill}
            />
            <label htmlFor="fileInput" style={{ position: "relative", zIndex: "0" }}>
                <button className='button-default button-primary' onClick={handleButtonClickFileUpload}>Upload Bills</button>
            </label>
            {!!bills && bills.length > 0 && bills.map((bill, index) => (
                <FileContainer
                    key={bill.lastModified || bill.googleDriveId || index}
                    bill={bill}
                    handleRemove={handleRemoveBill}
                    shortenName
                />
            ))}

        </>
    )
}
