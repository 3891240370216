import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { generateArrayOfValuesFromMonths, getMaxValueFromTwoArrays } from '../../services/arrays';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export default function BarChartProductionVsConsumption(props) {
    const {
        usage,
        system
    } = props;

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const dependentLabel = "Energy (kWh)";
    const usageArray = generateArrayOfValuesFromMonths(usage);
    const productionArray = generateArrayOfValuesFromMonths(system);
    const maxValue = getMaxValueFromTwoArrays([...usageArray, ...productionArray]);
    const suggestedMax = maxValue + 200;
    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Estimated Solar Production vs Home Energy Consumption',
            },
            datalabels: {
                display: false
            }
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                suggestedMax,
                title: {
                    display: !!dependentLabel,
                    text: dependentLabel
                },
                stacked: false,
                ticks: {
                    beginAtZero: true,
                    // callback: function(value, index, ticks) {
                    //     return value + " kWh";
                    // }
                }
            },
        }
    }
    const data = {
        labels,
        datasets: [
            {
                label: 'Solar Production',
                data: productionArray,
                backgroundColor: 'rgba(147, 178, 166, 1)',
            },
            {
                label: 'Energy Consumption',
                data: usageArray,
                backgroundColor: 'rgba(201, 188, 112, 1)',
            },
        ]
    };
    return (
        <Bar
        className='grid-bar-chart-1'
            options={options}
            data={data}
        />
    )
}
