import React, { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { getDownloadURL, ref } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import Container from './Container';
import LoadingIcon from '../components/LoadingIcon';
import useGetWithRetries from '../hooks/useGetWithRetries';
import { storage } from '../firebase';

import SystemImage from '../components/SystemImage';
import { getRoofImageSrc } from '../components/RoofImage';
import ImageTreepublicLogoWhite from '../images/treepublic_logo_white_large.png';
import ImageCleanEnergyToLife from '../images/roof/clean_energy_to_life.png';
import IconSunEnergy from '../icons/icon-sun-energy.png';
import IconPower from '../icons/icon-power.png';
import IconSavings from '../icons/icon-savings.png';
import IconHomeBattery from '../icons/icon-home-battery.png';
import IconGasPump from '../icons/icon-gas-pump.png';
import IconSapling from '../icons/icon-sapling.png';
import IconSolarPanel from '../icons/icon-solar-panel.png';
import IconFridge from '../icons/icon-fridge.png';
import IconLightbulb from '../icons/icon-bulb.png';
import IconLaptop from '../icons/icon-laptop.png';
import { Typography } from '@mui/material';
import {
    getEmailFromLead,
    getAddressFromLead,
    getFullNameFromLead,
    getPhoneFromLead,
    getOffsetFromLead,
    getTotalSystemCostFromLead,
    getTotalBatteryCostFromLead,
    getTotalAddersFromLead,
    getTaxCreditsFromLead,
    getTotalSystemBatteryAddersCostAfterTaxCreditsFromLead,
    getMonthlyPaymentWithTaxCreditFromLead,
    getMonthlyPaymentNoTaxCreditFromLead,
    getLoanTermFromLead,
    getInterestRateFromLead,
    getIsCashDealFromLead,
    getShouldRetryFetchProposal,
    getGallonsOfGasFromProduction,
    getTreesPlantedFromProduction,
    getBatteryNameFromLead,
    getBatteryRebateAmountFromLead,
    getBatteryCapacityFromLead,
    getPotentialSolarSavingsFromLead,
    getBatteryImageSrc,
    getTotalSystemBatteryAddersCostFromLead
} from '../services/leads';
import {
    parsePercentage
} from '../utils/strings';
import BarChartProductionVsConsumption from '../components/Charts/BarChartProductionVsConsumption';
import PieChartProductionVsConsumption from '../components/Charts/PieChartProductionVsConsumption';
import { getAnnualProductionFromSystem, getNumberOfPanelsFromSystem, getPanelModelFromSystem, getSystemSizeFromSystem, getWattsPerPanelFromSystem } from '../services/system';
import ProposalMenu from '../components/ProposalMenu';
import { generateCalendlyLink } from '../services/calendar';
import SignatureModal from '../components/SignatureModal';
import { useAuth } from '../contexts/AuthContext';

export default function ProposalPage() {
    const { currentUser } = useAuth();
    const { proposalId } = useParams();
    const maxRequests = 10;
    const retryDelay = 6000;
    const { data, isLoading, hasError, requestCount } = useGetWithRetries(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}`, false, true, { requestConditionHandler: getShouldRetryFetchProposal, maxRequests, retryDelay });
    console.log("PROPOSAL PAGE REQUEST COUNT: " + requestCount);
    const proposal = !!data && data.proposal || {};
    const {
        system,
        usage
    } = proposal;

    const customerFullName = getFullNameFromLead(proposal);
    const customerPhone = getPhoneFromLead(proposal);
    const customerEmail = getEmailFromLead(proposal);
    const address = getAddressFromLead(proposal);
    const numberOfPanels = getNumberOfPanelsFromSystem(system);
    const wattsPerPanel = getWattsPerPanelFromSystem(system);
    const systemSize = getSystemSizeFromSystem(system);
    const panelModel = getPanelModelFromSystem(system);
    const kwhOffset = getOffsetFromLead(proposal);
    const annualProduction = getAnnualProductionFromSystem(system);
    const savings = getPotentialSolarSavingsFromLead(proposal);
    const shouldShowSolarDesign = !!systemSize || !!annualProduction || !!numberOfPanels;
    // Solar System Cost
    // Total Gross Solar System + Additional Services Cost
    const totalSystemAndBatteryCost = getTotalSystemBatteryAddersCostFromLead(proposal);
    const totalSystemCost = getTotalSystemCostFromLead(proposal);
    const totalBatteryCost = getTotalBatteryCostFromLead(proposal);
    const totalAdders = getTotalAddersFromLead(proposal);
    const taxCredits = getTaxCreditsFromLead(proposal);
    const totalSystemAndBatteryCostAfterTaxCredits = getTotalSystemBatteryAddersCostAfterTaxCreditsFromLead(proposal);
    const monthlyPaymentWithTaxCredit = getMonthlyPaymentWithTaxCreditFromLead(proposal);
    const monthlyPaymentNoTaxCredit = getMonthlyPaymentNoTaxCreditFromLead(proposal);
    const loanTerm = getLoanTermFromLead(proposal);
    const interestRate = getInterestRateFromLead(proposal);
    const isCashDeal = getIsCashDealFromLead(proposal);
    const gallonsOfGas = getGallonsOfGasFromProduction(annualProduction);
    const treesPlanted = getTreesPlantedFromProduction(annualProduction);
    const batteryName = getBatteryNameFromLead(proposal);
    const batteryCapacityKwh = getBatteryCapacityFromLead(proposal);
    const batteryRebateAmount = getBatteryRebateAmountFromLead(proposal);
    const roofImageSrc = getRoofImageSrc(proposal.roofType);
    const batteryImageSrc = getBatteryImageSrc(batteryName);
    const calendlyLink = generateCalendlyLink(proposal);

    const handleOpenCalendlyLink = e => {
        e.preventDefault();
        window.open(calendlyLink);
    }

    console.log(proposal);
    const [systemImageUrl, setSystemImageUrl] = useState("loading");
    const [showSignatureModal, setShowSignatureModal] = useState(false);

    const handleTriggerSignatureModal = e => {
        e.preventDefault();
        console.log("handleTriggerSignatureModal");
        setShowSignatureModal(true);
    }

    const handleCloseModals = e => {
        !!e && e.preventDefault();
        setShowSignatureModal(false);
    }

    useEffect(() => {
        console.log("useEffect");
        const fetchImage = async () => {
            if (!!data) {
                if (data.proposal && data.proposal.systemImagePathname) {
                    const systemImagePathname = data.proposal.systemImagePathname;
                    console.log("systemImagePathname: " + systemImagePathname);
                    try {
                        const imageRef = ref(storage, systemImagePathname);
                        const url = await getDownloadURL(imageRef);
                        console.log({ url });
                        setSystemImageUrl(url);
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        setSystemImageUrl(null);
                        Sentry.captureException(error);
                    }
                } else {
                    setSystemImageUrl(null);
                }
            }
        };

        fetchImage();
    }, [data]);
    if (hasError) {
        return (
            <Container showSettings responsive>
                <Typography>Error.  Please contact support.</Typography>
            </Container>
        )
    } else if (isLoading) {
        return (
            <div className="proposal-container">
                <div style={{ position: "absolute", right: "20px", top: "20px" }}>
                    <ProposalMenu lead={proposal} color="white" />
                </div>
                <div className='proposal'>
                    <div className="flex-center">
                        <img className="treepublic-logo-image" src={ImageTreepublicLogoWhite} alt="treepublic logo" />
                    </div>
                    <p className="proposal-title mb-4 mt-4">WHAT WE'RE KNOWN FOR</p>
                    <ul className="mb-4 list">
                        <li className="mb-2">In-House Team: Our employees are W2 in-house professionals, not subcontractors.</li>
                        <li className="mb-2">Revitalising Systems: We have repaired over 1,200 systems installed by other companies, freeing stranded solar and storage assets for institutions, companies, and individuals while their original installer has gone MIA.</li>
                        <li className="mb-2">Prompt Warranty Service: While we have bandwidth to help with other companies' client systems, we continue to prioritize our own installations, ensuring that service requests are handled promptly. We rarely go into the weekend with any backlogged service calls.</li>
                        <li className="mb-2">Superior Equipment Selection: We are hyper-sensitive to equipment selection. We promote and build with only the highest quality, apex products in every vertical. A Tier 1 designation doesn't always mean the panel or manufacturer meets our standard.</li>
                        <li className="mb-2">Raving Fans: Prioritizing courtesy and competence in all our hiring decisions, we have a stellar client review record evidenced by our nearly flawless and 100% authentic client rating over a 10+ yr period across multiple reference sites.</li>
                        <li className="mb-2">Long Team Stability: We are structured to be balanced and lean, with solar/storage/EV projects spanning government/institutional/corporate to homes of all sizes, including off-grid, and a client base that largely self-finances, allowing us to weather economic downturns and inflationary/interest-rate pressures. We build systems in our expanding footprint in the Southwestern US, we have a distribution channel that promotes and sells clean energy products to all 50 states and over 25 countries around the world. Lastly, Treepublic is a zero-debt company that owns its entire cargo vehicle fleet as well as major warehouse and office facilities, free and clear. Whereas competitors large and small tend to crumble under the weight of obligations when markets sour, Treepublic is built differently.</li>
                    </ul>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <SignatureModal
                    open={showSignatureModal}
                    handleClose={handleCloseModals}
                    proposalId={proposalId}
                />
                <div className="proposal-container">
                    <div style={{ position: "absolute", right: "20px", top: "20px" }}>
                        <ProposalMenu lead={proposal} color="white" />
                    </div>
                    <div className='proposal'>
                        <div className="flex-center">
                            <img className="treepublic-logo-image" src={ImageTreepublicLogoWhite} alt="treepublic logo" />
                        </div>
                        <p className="proposal-title mb-4 mt-4">WHAT WE'RE KNOWN FOR</p>
                        <ul className="mb-8 list">
                            <li className="mb-2">In-House Team: Our employees are W2 in-house professionals, not subcontractors.</li>
                            <li className="mb-2">Revitalising Systems: We have repaired over 1,200 systems installed by other companies, freeing stranded solar and storage assets for institutions, companies, and individuals while their original installer has gone MIA.</li>
                            <li className="mb-2">Prompt Warranty Service: While we have bandwidth to help with other companies' client systems, we continue to prioritize our own installations, ensuring that service requests are handled promptly. We rarely go into the weekend with any backlogged service calls.</li>
                            <li className="mb-2">Superior Equipment Selection: We are hyper-sensitive to equipment selection. We promote and build with only the highest quality, apex products in every vertical. A Tier 1 designation doesn't always mean the panel or manufacturer meets our standard.</li>
                            <li className="mb-2">Raving Fans: Prioritizing courtesy and competence in all our hiring decisions, we have a stellar client review record evidenced by our nearly flawless and 100% authentic client rating over a 10+ yr period across multiple reference sites.</li>
                            <li className="mb-2">Long Team Stability: We are structured to be balanced and lean, with solar/storage/EV projects spanning government/institutional/corporate to homes of all sizes, including off-grid, and a client base that largely self-finances, allowing us to weather economic downturns and inflationary/interest-rate pressures. We build systems in our expanding footprint in the Southwestern US, we have a distribution channel that promotes and sells clean energy products to all 50 states and over 25 countries around the world. Lastly, Treepublic is a zero-debt company that owns its entire cargo vehicle fleet as well as major warehouse and office facilities, free and clear. Whereas competitors large and small tend to crumble under the weight of obligations when markets sour, Treepublic is built differently.</li>
                        </ul>
                        <p className="proposal-title mb-4">SOLAR PROPOSAL FOR</p>
                        <div className="proposal-callout mb-4 text-center">
                            <p className="inter-bold">{customerFullName}</p>
                            <p className='inter-light'>{address}</p>
                            <p className='inter-light'>{customerEmail}</p>
                            <p className='inter-light'>{customerPhone}</p>
                        </div>
                        {!!currentUser && (
                            <div className='mb-8 flex-buttons-mobile'>
                                <button
                                    className='button-default button-primary'
                                    onClick={handleOpenCalendlyLink}
                                >
                                    Click to set up an in-person proposal
                                </button>
                                <button
                                    className='button-default button-primary'
                                    onClick={handleTriggerSignatureModal}
                                >
                                    Click to move forward with install
                                </button>
                            </div>
                        )}
                        {(!!shouldShowSolarDesign) && <>
                            <p className="proposal-title mb-4">Design</p>
                            <div className='proposal-grid'>
                                <div className='proposal-card span-6 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img className="icon" style={{ marginRight: "10px", marginBottom: "5px" }} alt="sun energy icon" src={IconPower} />
                                        <p className='inter-light text-large-responsive'>
                                            {systemSize || 0}
                                            <span className='super'>kW</span>
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>System Size</p>
                                </div>
                                <SystemImage className="span-6 row-span-4 mobile-span-12" isLoading={systemImageUrl === "loading"} imageSrc={systemImageUrl} />
                                <div className='proposal-card proposal-card-secondary span-2 mobile-span-4'>
                                    <p className='inter-light text-large-responsive'>{numberOfPanels}</p>
                                    <p className='inter-light italic'>Panels</p>
                                </div>
                                <div className='proposal-card proposal-card-secondary span-4 mobile-span-8'>
                                    <div className='flex-start mb-1'>
                                        <img className="icon" style={{ marginRight: "10px", marginBottom: "5px" }} alt="solar panel icon" src={IconSolarPanel} />
                                        <p className='inter-light text-large-responsive'>
                                            {wattsPerPanel || 0} watts
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>{panelModel}</p>
                                </div>
                                <div className='proposal-card span-6 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img className="icon" style={{ marginRight: "10px", marginBottom: "5px" }} alt="sun energy icon" src={IconSunEnergy} />
                                        <p className='inter-light text-large-responsive'>
                                            {annualProduction || 0}
                                            <span className='super'>kWh</span>
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>Estimated Yearly Production</p>
                                </div>
                                <div className='proposal-card proposal-card-secondary span-12 mobile-span-12 responsive-height-800-300' style={{ boxSizing: "border-box", overflow: "hidden" }}>
                                    <BarChartProductionVsConsumption system={system && system.production} usage={usage} />
                                </div>
                                <div
                                    className='proposal-card proposal-card-secondary proposal-card-image span-6 mobile-span-12 row-span-2'
                                    style={{ backgroundPosition: "center", backgroundImage: `url(${roofImageSrc})`, minHeight: "400px" }}
                                >
                                </div>
                                <div className='proposal-card proposal-card-secondary span-6 mobile-span-12 flex-vertical-center p-2'>
                                    <div style={{ maxHeight: "200px", maxWidth: "100%" }}>
                                        <PieChartProductionVsConsumption
                                            system={system && system.production}
                                            usage={usage}
                                            fontSize={14}
                                        />
                                    </div>
                                    <p className='inter-light italic text-center mt-4'>Offset</p>
                                </div>
                                <div className='proposal-card proposal-card-primary span-6 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img className="icon" style={{ marginRight: "10px", marginBottom: "5px" }} alt="savings icon" src={IconSavings} />
                                        <p className='inter-light text-large-responsive'>
                                            {savings || ""}
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>Estimated Savings</p>
                                </div>
                            </div>
                        </>}
                        {(!!batteryName) && (
                            <>
                                <p className="proposal-title mt-8 mb-4">Energy Storage</p>
                                <div className='proposal-grid'>
                                    <div
                                        className='proposal-card proposal-card-image proposal-card-secondary span-6 row-span-3 mobile-span-12'
                                        style={{ minHeight: '500px', backgroundPosition: "center", backgroundImage: `url(${batteryImageSrc})` }}
                                    >
                                    </div>
                                    <div className='proposal-card proposal-card-secondary span-6 mobile-span-12'>
                                        <div className='flex-start mb-1'>
                                            <p className='inter-light text-large-responsive'>
                                                1x {batteryName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='proposal-card span-3 mobile-span-12'>
                                        <div className='flex-start mb-1'>
                                            <img className="icon tablet-hidden" style={{ marginRight: "10px", marginBottom: "5px" }} alt="battery icon" src={IconHomeBattery} />
                                            <p className='inter-light text-large-responsive'>
                                                {batteryCapacityKwh || 0}
                                                <span className='super'>kWh</span>
                                            </p>
                                        </div>
                                        <p className='inter-light italic'>Capacity</p>
                                    </div>
                                </div>
                            </>
                        )}
                        <p className="proposal-title mt-8 mb-4">Investment Summary</p>
                        <div className='proposal-grid'>

                            <div
                                className='proposal-card proposal-card-secondary span-6 mobile-span-12 proposal-card-image background-clean-energy-to-life'
                            >
                            </div>
                            <div className='proposal-card proposal-card-secondary span-6 mobile-span-12'>
                                <p className='inter-light text-large-responsive'>{totalSystemCost}</p>
                                <p className='inter-light italic'>Solar System Cost</p>
                                <p className='inter-light text-large-responsive'>{totalBatteryCost}</p>
                                <p className='inter-light italic'>Battery Cost</p>
                                <p className='inter-light text-large-responsive'>{totalAdders}</p>
                                <p className='inter-light italic'>Additional Services</p>
                                <p className='inter-light text-large-responsive'>{totalSystemAndBatteryCost}</p>
                                <p className='inter-light italic'>Total Gross Solar System + {!!batteryName ? `Battery + ` : ""}Additional Services Cost</p>
                                <p className='inter text-large-responsive' style={{ color: "green" }}>{taxCredits}</p>
                                <p className='inter-light italic' style={{ color: "green" }}>Estimated Total Solar Federal Tax <span className='bold inter'>Credit</span> (30%)<span className='super'>1</span></p>
                                {batteryRebateAmount && (<>
                                    <p className='inter text-large-responsive' style={{ color: "green" }}>{batteryRebateAmount}</p>
                                    <p className='inter-light italic' style={{ color: "green" }}>Estimated Total Battery <span className='bold inter'>Rebate</span></p>
                                </>)}
                                <p className='inter-light text-large-responsive'>{totalSystemAndBatteryCostAfterTaxCredits}</p>
                                <p className='inter-light italic'>Estimated Net Cost, After Rebates / Credits<span className="super">2</span></p>
                            </div>
                            {!isCashDeal && (
                                <>
                                    <div className='proposal-card proposal-card-secondary span-3 mobile-span-12'>
                                        <p className='inter text-large-responsive' style={{ color: "green" }}>{!!monthlyPaymentWithTaxCredit ? `${monthlyPaymentWithTaxCredit}` : ""}</p>
                                        <p className='inter-light italic'>Est. Monthly Payment (With 30% Tax Credit Applied)</p>
                                    </div>
                                    <div className='proposal-card proposal-card-secondary span-3 mobile-span-12'>
                                        <p className='inter-light text-large-responsive'>{!!loanTerm ? `${loanTerm} years` : ""}</p>
                                        <p className='inter-light italic'>Loan Term</p>
                                    </div>
                                    <div className='proposal-card proposal-card-secondary span-3 mobile-span-12'>
                                        <p className='inter-light text-large-responsive'>{!!interestRate ? `${parsePercentage(interestRate)}` : ""}</p>
                                        <p className='inter-light italic'>Interest Rate</p>
                                    </div>
                                    <div className='proposal-card proposal-card-secondary span-3 mobile-span-12'>
                                        <p className='inter-light text-large-responsive'>{!!monthlyPaymentNoTaxCredit ? `${monthlyPaymentNoTaxCredit}` : ""}</p>
                                        <p className='inter-light italic'>Est. Monthly Payment (Without 30% Tax Credit Applied)</p>
                                    </div>
                                </>
                            )}
                            {!!shouldShowSolarDesign && (<>
                                <p className='inter-light span-12 text-center flex-center' style={{ marginTop: "auto" }}>By going solar, you can save: </p>
                                <div className='proposal-card proposal-card-secondary span-6 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img style={{ marginRight: "10px", marginBottom: "5px" }} src={IconGasPump} alt="gas pump icon" className='icon' />
                                        <p className='inter-light text-large-responsive'>
                                            {gallonsOfGas || ""}
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>Gallons of gas burned over 20 years</p>
                                </div>
                                <div className='proposal-card proposal-card-secondary span-6 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img style={{ marginRight: "10px", marginBottom: "5px" }} src={IconSapling} alt="sapling icon" className='icon' />
                                        <p className='inter-light text-large-responsive'>
                                            {treesPlanted || ""}
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>Trees planted over 20 years</p>
                                </div>
                            </>)}
                            {!!batteryName && (<>
                                <p className='inter-light span-12 text-center flex-center' style={{ marginTop: "auto" }}>By adding a battery setup, you can store and discharge solar energy to power: </p>
                                <div className='proposal-card proposal-card-secondary span-4 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img style={{ marginRight: "10px", marginBottom: "5px" }} src={IconFridge} alt="fridge icon" className='icon' />
                                        <p className='inter-light text-large-responsive'>
                                            400 W
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>A standard refrigerator for up to 50 hours</p>
                                </div>
                                <div className='proposal-card proposal-card-secondary span-4 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img style={{ marginRight: "10px", marginBottom: "5px" }} src={IconLightbulb} alt="lightbulb icon" className='icon' />
                                        <p className='inter-light text-large-responsive'>
                                            133 W
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>or ten (10) LED light bulbs for use over 10 hours</p>
                                </div>
                                <div className='proposal-card proposal-card-secondary span-4 mobile-span-12'>
                                    <div className='flex-start mb-1'>
                                        <img style={{ marginRight: "10px", marginBottom: "5px" }} src={IconLaptop} alt="laptop icon" className='icon' />
                                        <p className='inter-light text-large-responsive'>
                                            250 W
                                        </p>
                                    </div>
                                    <p className='inter-light italic'>or four (4) laptops for use over 80 hours</p>
                                </div>
                            </>)}
                            <div className="span-12">
                                <p>1 Based on tax liability.  2 Solar payback period accelerates when accounting for higher utility rate increases (assumption = 3.7% year on year increase) & subsequent rise in property value. Based on current utility rates, which varies depending on time/seasonality of energy use & factors such as panel cleanliness</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

