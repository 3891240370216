const { getFullNameFromLead, getEmailFromLead, getPhoneFromLead } = require("./leads");

const baseCalendlyLink = "https://calendly.com/treepublicfollowup/introduction/";

function formatPhoneForCalendly(phone) {
    if (!!phone) {
        // Remove any non-digit characters from the phone number
        const cleanedPhoneNumber = phone.replace(/\D/g, '');

        // Check if the cleaned phone number has exactly 10 digits
        if (cleanedPhoneNumber.length === 10) {
            // Prepend the country code "1" to the phone number
            const formattedPhoneNumber = '1' + cleanedPhoneNumber;
            return formattedPhoneNumber;
        }

        // Return nothing if the phone number is not the right length
        return '';
    }
}

function generateCalendaryQueryParametersFromLead(lead) {
    let queryParameters = '';
    if (!!lead) {
        const fullName = getFullNameFromLead(lead);
        const email = getEmailFromLead(lead);
        const phone = getPhoneFromLead(lead);
        console.log(phone);
        if (!!fullName) {
            if (!!queryParameters) {
                queryParameters += "&"
            }
            queryParameters += `full_name=${fullName}`;
        }
        if (!!email) {
            if (!!queryParameters) {
                queryParameters += "&"
            }
            queryParameters += `email=${email}`;
        }
        if (!!phone) {
            const formattedPhone = formatPhoneForCalendly(phone);
            if (!!queryParameters) {
                queryParameters += "&"
            }
            queryParameters += `location=${formattedPhone}`;
        }
    }
    return queryParameters;
}

function generateCalendlyLink(lead) {
    const queryParameters = generateCalendaryQueryParametersFromLead(lead);
    return baseCalendlyLink + (!!queryParameters ? ("?" + queryParameters) : "");
}

module.exports = {
    generateCalendlyLink
}
