import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Container from './Container';
import useGet from '../hooks/useGet';
import LoadingIcon from '../components/LoadingIcon';
import StyledInput from '../components/StyledInput';
import batteries from '../data/batteries.json';
import { getBatteryCostByName, getBatteryCapacityByName } from '../services/batteries';
import { useAuth } from '../contexts/AuthContext';
import { getBatteryCapacityFromLead, getBatteryCostFromLead, getBatteryNameFromLead } from '../services/leads';

export default function BatteryPage() {
    const { proposalId } = useParams();
    const { currentUser } = useAuth();
    const { data, isLoading, hasError } = useGet(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}`);
    const proposal = !!data && data.proposal || {};
    const initialState = {
        batteryName: '',
        batteryCost: '',
        batteryCapacity: ''
    }
    console.log(initialState);
    const [state, setState] = useState(initialState);

    const {
        batteryName,
        batteryCost,
        batteryCapacity
    } = state;

    useEffect(() => {
        const batteryName = getBatteryNameFromLead(proposal);
        const batteryCost = getBatteryCostFromLead(proposal);
        const batteryCapacity = getBatteryCapacityFromLead(proposal);
        if ((!!batteryName && batteryName !== state.batteryName) || (!!batteryCost && batteryCost !== state.batteryCost)) {
            setState(prevState => ({
                ...prevState,
                batteryName,
                batteryCost,
                batteryCapacity
            }));
        }
    }, [data]);

    const handleChange = e => {
        if (e.target.name === "batteryName") {
            setState(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value,
                batteryCapacity: getBatteryCapacityByName(e.target.value),
                batteryCost: getBatteryCostByName(e.target.value)
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }
    }

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            console.log("handleSubmit");
            const data = {
                id: proposal._id,
                wantsBattery: !!state.batteryCost || !!state.batteryName,
                batteryCost: state.batteryCost,
                batteryCapacity: state.batteryCapacity,
                batteryName: state.batteryName,
                batteryUpdated: true
            }
            console.log(data);
            setState({
                ...state,
                isLoading: true
            });
            const tokenIdResult = await currentUser.getIdTokenResult();
            const newToken = !!tokenIdResult && tokenIdResult.token;
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/api/leads`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ` + newToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        } catch (error) {
            console.log({ errorHandleSubmit: error });
            setState({
                ...state,
                hasError: true
            });
        }
    }

    const enableSubmit = !batteryName || (!!batteryName);

    if (hasError) {
        return (
            <Container showSettings responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading || state.isLoading) {
        return (
            <Container showSettings responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else {
        return (
            <Container proposalLogo showSettings responsive lead={proposal}>
                <form onSubmit={handleSubmit}>
                    <p className='text-medium mb-1'>Battery</p>
                    <StyledInput
                        // label="Select battery"
                        name="batteryName"
                        id="batteryName"
                        value={batteryName}
                        onChange={handleChange}
                        mb
                        type="dropdown"
                        required
                    >
                        <option value="">Select battery</option>
                        {batteries.map((item, index) => (
                            <option key={index} value={item.name}>
                                {item.name}
                            </option>
                        ))}
                    </StyledInput>
                    <p className='text-medium mb-1'>Cost</p>
                    <StyledInput
                        name="batteryCost"
                        id="batteryCost"
                        placeholder="Enter battery cost"
                        type="text"
                        value={batteryCost}
                        onChange={handleChange}
                        mb
                    />
                    <p className='text-medium mb-1'>Capacity (kWh)</p>
                    <StyledInput
                        name="batteryCapacity"
                        id="batteryCapacity"
                        placeholder="Enter battery capacity"
                        type="text"
                        value={batteryCapacity}
                        onChange={handleChange}
                        mb
                    />
                    <button className="button-default button-primary" type="submit" disabled={!enableSubmit}>Update</button>
                </form>
            </Container>
        )
    }
}
