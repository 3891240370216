import React, { useState, useRef } from 'react';
import * as Sentry from "@sentry/react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import TreepublicTitleImage from '../images/treepublic_logo_large.png';

import NoticeCard from '../components/NoticeCard';
import TreepublicLogoClickable from '../components/TreepublicLogoClickable';

export default function LoginPage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  // todo - remove loading?
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/leads/new");
    } catch (error) {
      console.log(error);
      setError('Failed to sign in.  Please reach out to support if you believe this was an error.');
      Sentry.captureException(error);
    }
    setLoading(false);
  }

  function handleRedirect (e, route) {
    e.preventDefault();
    navigate(route);
  }

  return (
    <>
      <div className="flex-vertical-center h-30vh">
        {TreepublicTitleImage && <TreepublicLogoClickable route="/" />}
        {!TreepublicTitleImage && <h2 className="mb-1 image-small">Treepublic</h2>}
      </div>
        <div className="container-rounded-top responsive-width-100-50">
        <form className="text-center p-0-4 flex-vertical flex-grow-8" onSubmit={handleSubmit}>
            <p className='ubuntu dark f-2'>Enter Your Login Information</p>
            {error && <NoticeCard text={error} type="warning" />}
            <div className='w-100'>
              <input
                className="styled-input m-auto mt-3"
                placeholder="Email..."
                id="email"
                aria-label="email address"
                ref={emailRef}
              />
              <input
                className="styled-input m-auto mt-3"
                placeholder="Password..."
                id="password"
                type="password"
                aria-label="password"
                ref={passwordRef}
              />
            </div>
            <button className="button-default button-primary" type="submit">Submit</button>
          </form>
          <p className="text-center flex-center flex-grow-2 mb-1 mt-1"><a className='fw-light underline grey' onClick={e => handleRedirect(e, '/forgot-password')}>Forgot password?</a></p>
        </div>
    </>
  )
}
