import React from 'react'
import { Box, Modal } from '@mui/material';

export default function MuiModal(props) {
    const {
        open,
        onClose
    } = props;
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '100vw',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                {props.children}
            </Box>
        </Modal>
    )
}
