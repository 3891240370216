import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";

import LandingPage from "./containers/LandingPage";
import PageNotFound from "./containers/PageNotFound";
import Signup from "./containers/Signup";
import SettingsPage from "./containers/SettingsPage";
import LoginPage from "./containers/LoginPage";
import ForgotPasswordPage from "./containers/ForgotPasswordPage";
import ProposalForm from "./containers/ProposalForm";
import PrivateRoute, { PublicRouteWithAuthRedirect } from "./components/PrivateRoute";
import ProposalPage from "./containers/ProposalPage";
import { createTheme, responsiveFontSizes } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import SystemDesignPage from "./containers/SystemDesignPage";
import BasicInfoPage from "./containers/BasicInfoPage";
import BatteryPage from "./containers/BatteryPage";
import UsagePage from "./containers/UsagePage";
import PricingPage from "./containers/PricingPage";
import useVersion from "./hooks/useVersion";
import Container from "./containers/Container";
import LoadingIcon from "./components/LoadingIcon";
import ProposalFormCustomerFacing from "./containers/ProposalFormCustomerFacing";
import PublicDesignPage from "./containers/PublicDesignPage";
import { getIsSiteCustomerFacing } from "./services/site";

function App() {

  const isCustomerFacing = getIsSiteCustomerFacing();
  console.log({isCustomerFacing});
  let theme = createTheme({
    palette: {
      primary: {
        main: "#c9bb70",
      },
      secondary: {
        main: "#93b2a6",
      },
    },
    typography: {
      h5: {
        marginTop: 8,
        fontSize: '2rem',
        '@media (min-width:600px)': {
          fontSize: '2.5rem',
        },
        '@media (min-width:960px)': {
          fontSize: '3rem',
        },
      },
      h6: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1.75rem',
        },
        '@media (min-width:960px)': {
          fontSize: '2rem',
        },
      },
      padding: 8,
    },
  });
  const { version, isLoading } = useVersion();
  console.log({ version });
  theme = responsiveFontSizes(theme);
  if (isLoading) {
    return (
      <ThemeProvider theme={theme} >
        <Router>
          <AuthProvider>
            <Container responsive>
              <LoadingIcon fitScreen />
            </Container>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    )
  } else if (isCustomerFacing) {
    return (
      <ThemeProvider theme={theme} >
        <Router>
          <Routes>
            {/* to do customer facing - redirect to /leads/new instead of showing landing page */}
            <Route exact path='/' element={<LandingPage />} />
            <Route exact path='/leads/new' element={<ProposalFormCustomerFacing />} />
            <Route exact path='/:proposalId' element={<ProposalPage />} />
            {/* to do customer facing - make this pretty and redirect to the homepage or elsewhere */}
            <Route exact path='*' element={<PageNotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme} >
        <Router>
          <AuthProvider>
            <Routes>
            <Route exact path='/design' element={<PublicDesignPage />} />
              {/* to do - the below route is different for the customer-facing app */}
              <Route exact path='/' element={<PublicRouteWithAuthRedirect />}>
                <Route exact path='/' element={<LandingPage />} />
              </Route>
              <Route path="/signup" element={<PublicRouteWithAuthRedirect />}>
                <Route exact path='/signup' element={<Signup />} />
              </Route>
              <Route path="/login" element={<PublicRouteWithAuthRedirect />}>
                <Route exact path='/login' element={<LoginPage />} />
              </Route>
              <Route path="/forgot-password" element={<PublicRouteWithAuthRedirect />}>
                <Route exact path='/forgot-password' element={<ForgotPasswordPage />} />
              </Route>
              <Route exact path='/settings' element={<PrivateRoute />}>
                <Route exact path='/settings' element={<SettingsPage />} />
              </Route>
              {/* to do - the below route is different for the customer-facing app */}
              <Route exact path='/leads/new' element={<PrivateRoute />}>
                <Route exact path='/leads/new' element={<ProposalForm />} />
              </Route>
              <Route exact path='/:proposalId' element={<ProposalPage />} />
              <Route exact path='/:proposalId/system-designs' element={<PrivateRoute />}>
                <Route exact path='/:proposalId/system-designs' element={<SystemDesignPage />} />
              </Route>
              <Route exact path='/:proposalId/info' element={<PrivateRoute />}>
                <Route exact path='/:proposalId/info' element={<BasicInfoPage />} />
              </Route>
              <Route exact path='/:proposalId/battery' element={<PrivateRoute />}>
                <Route exact path='/:proposalId/battery' element={<BatteryPage />} />
              </Route>
              <Route exact path='/:proposalId/usage' element={<PrivateRoute />}>
                <Route exact path='/:proposalId/usage' element={<UsagePage />} />
              </Route>
              <Route exact path='/:proposalId/pricing' element={<PrivateRoute />}>
                <Route exact path='/:proposalId/pricing' element={<PricingPage />} />
              </Route>
              <Route exact path='*' element={<PageNotFound />} />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
