import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from './Container';
import TreepublicTitleImage from '../images/treepublic_logo_large.png';
import Version from '../components/Version';

export default function LandingPage() {
    const navigate = useNavigate();

    function handleRedirect(e, route) {
        !!e && e.preventDefault();
        navigate(route);
    }

    return (
        <>
            <Container padding responsive>
                <div className="container-70vh responsive-width-100-50 p-4-0">
                    <div className='button-container-vertical mt-4'>
                        <p className='text-center mb-4'>Proposals</p>
                        <button className='mt-4 button-default button-primary' styles={{ display: 'block' }} onClick={e => handleRedirect(e, '/login')}>LOGIN</button>
                        <button className='mt-4 button-default button-secondary' styles={{ display: 'block' }} onClick={e => handleRedirect(e, '/signup')}>SIGNUP</button>
                    </div>
                    <Version />

                </div>
            </Container>
        </>
    )
}
