import React from 'react';
import { useParams } from 'react-router-dom';
import Container from './Container';
import useGet from '../hooks/useGet';
import LoadingIcon from '../components/LoadingIcon';

export default function SystemDesignPage() {
    const { proposalId } = useParams();
    const { data, isLoading, hasError } = useGet(`${process.env.REACT_APP_API_URL}/api/public/proposals/${proposalId}`);
    const proposal = !!data && data.proposal || {};
    const openSolarIframeSrc = proposal.openSolarIframeSrc;

    if (hasError) {
        return (
            <Container showSettings responsive>
                <p>Error.  Please contact support.</p>
            </Container>
        )
    } else if (isLoading) {
        return (
            <Container showSettings responsive>
                <LoadingIcon fitScreen />
            </Container>
        )
    } else {
        return (
            <Container proposalLogo showSettings lead={proposal}>
                <iframe
                    title="Open Solar"
                    style={{ width: '100%', minHeight: '90vh' }}
                    src={openSolarIframeSrc}
                />
            </Container>
        )
    }
}
