import axios from 'axios';
import * as Sentry from "@sentry/react";
import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingIcon from '../components/LoadingIcon';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  const [currentUser, setCurrentUser] = useState();
  const [userMetadata, setUserMetadata] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  async function handleGetUserMetadata(user) {
    try {
      if(!!user && !!user.getIdTokenResult) {
        const uid = user.uid || '';
        const tokenIdResult = await user.getIdTokenResult()
        if(!!tokenIdResult) {
          const token = tokenIdResult.token;
          return axios.get(`${process.env.REACT_APP_API_URL}/api/user/${uid}`, {
            headers: {
              Authorization: `Bearer ` + token
            }
          }).then((response) => {
            const {
              user
            } = response.data;
            if(user) {
              setUserMetadata(user);
            }
          })
        }
      }
    } catch (error) {
      console.log({errorHandleGetUserMetadata: error});
      Sentry.captureException(error);
    }
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    // handleGetUserMetadata();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user);
        // handleGetUserMetadata(user).then(() => {
          setLoading(false);
        // });
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userMetadata,
    signup,
    login,
    handleGetUserMetadata,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }
  console.log("AUTH CONTEXT");
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = !!location && location.pathname;
  if(loading) {
    return (
      <AuthContext.Provider value={value}>
        <LoadingIcon fitScreen />
      </AuthContext.Provider>
    )
  } else if (!!pathname && pathname === "/" && !!currentUser) {
    navigate('/leads/new');
  } else {
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    )
  }
}
