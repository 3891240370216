import React from 'react'
import ImageTreepublicLogo from '../images/treepublic_logo.png';
import ProposalMenu from '../components/ProposalMenu';
import { useNavigate } from 'react-router-dom';

export default function Container(props) {
    const {
        hideLogo,
        showSettings,
        lead,
        proposalLogo
    } = props;
    const navigate = useNavigate();
    let classes = "";
    if (props.responsive) {
        classes += " responsive-width-90-70 text-center fixed-width-desktop h-min-100vh";
    }
    if (!!props.className) {
        classes += " " + props.className;
    }
    // if (!!navbarTop) {
    //     classes += " h-min-100vh-minus-navbar";
    // } else {
    //     classes += " h-min-100vh";
    // }
    const handleNavigateToProposal = e => {
        e.preventDefault();
        if (!!lead && !!lead.proposalId) {
            navigate(`/${lead.proposalId}`);
        }
    }

    return (
        <>
            {showSettings && (
                <div style={{ position: "fixed", right: "20px", top: "20px" }}>
                    <ProposalMenu lead={lead} />
                </div>
            )}
            {(!!proposalLogo) && (
                <div
                    className="mobile-mt-4"
                    style={{ marginTop: "4rem", display: 'flex', alignItems: "center", justifyContent: "center" }}
                >
                    <img
                        onClick={handleNavigateToProposal}
                        className="treepublic-logo-image pointer"
                        src={ImageTreepublicLogo}
                        alt="treepublic logo"
                    />
                </div>
            )}
            {(!hideLogo && !proposalLogo) && (
                <div className={!!showSettings ? "mobile-mt-4" : ""} style={{ marginTop: "4rem", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <img className="treepublic-logo-image" src={ImageTreepublicLogo} alt="treepublic logo" />
                </div>
            )}
            <div className={classes}>
                {props.children}
            </div>
        </>
    )
}
