function getIsSiteCustomerFacing() {
    console.log("getIsSiteCustomerFacing");
    // don't push this change live as 'proposals' until the team on the same page
    //   this takes over a previous subdomain we are already using for reps
    const customerFacingSubdomains = ['proposals-TESTING'];
    const hostname = window.location.hostname;
    const subdomainParts = hostname.split(".");
    console.log({ subdomainParts });

    if (subdomainParts.length >= 2) {
        const subdomain = subdomainParts[0].toLowerCase();
        console.log({ subdomain });
        return customerFacingSubdomains.includes(subdomain);
    }
}

module.exports = {
    getIsSiteCustomerFacing
}
