import React from 'react'
import { Box, FormControl, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';

export default function Usage(props) {
    const {
        handleUpdateState,
        usageMonth,
        usageMonthly,
        billMonthly,
        usageYearly,
        usageType
    } = props;

    function handleChange(e) {
        handleUpdateState({
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
            <div>
                <select
                    className='styled-input'
                    name="usageType"
                    id="usageType"
                    labelId="usageTypeLabel"
                    value={usageType}
                    onChange={handleChange}
                >
                    <option value="Yearly Usage">Yearly Usage (kWh)</option>
                    <option value="Monthly Usage">Monthly Usage (kWh)</option>
                    <option value="Monthly Bill">Monthly Bill Amount ($)</option>
                </select>
                {(usageType === "Yearly Usage") && (
                    <>
                        <p className="mb-1 text-medium">Yearly Usage (kWh)</p>
                        <input
                            className='styled-input'
                            type="number"
                            id="usageYearly"
                            name="usageYearly"
                            value={usageYearly}
                            onChange={handleChange}
                        ></input>
                    </>
                )}
                {(usageType === "Monthly Usage") && (
                    <>
                        <p className='text-medium mb-1'>Month</p>
                        <select
                            className='styled-input'
                            name="usageMonth"
                            id="usageMonth"
                            labelId="usageMonthLabel"
                            value={usageMonth}
                            onChange={handleChange}
                        >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                        <p className="mb-1 text-medium">Monthly Usage (kWh)</p>
                        <input
                            className='styled-input'
                            type="number"
                            id="usageMonthly"
                            name="usageMonthly"
                            value={usageMonthly}
                            onChange={handleChange}
                        ></input>
                    </>
                )}
                {(usageType === "Monthly Bill") && (
                    <>
                        <p className='text-medium mb-1'>Month</p>
                        <select
                            className='styled-input'
                            name="usageMonth"
                            id="usageMonth"
                            labelId="usageMonthLabel"
                            value={usageMonth}
                            onChange={handleChange}
                        >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                        <p className='text-medium mb-1'>Monthly Bill Amount ($)</p>
                        <input
                            className='styled-input'
                            type="number"
                            id="billMonthly"
                            name="billMonthly"
                            value={billMonthly}
                            onChange={handleChange}
                        ></input>
                    </>
                )}
            </div>
        </>
    )
}
