function parsePercentage(percentage) {
    if (!!percentage) {
        // 0.0599
        // return 5.99%
        return `${Math.round(percentage * 10000) / 100}%`;
    }
}

function getDoesStringContainString(parentString, childString) {
    const formattedParentString = !!parentString && !!parentString.trim() && parentString.trim().toLowerCase();
    const formattedChildString = !!childString && !!childString.trim() && childString.trim().toLowerCase();
    if (!!formattedParentString && formattedChildString && formattedParentString.includes(childString)) {
        return true;
    }
}

function formatStringDollars(string) {
    const number = parseFloat(string);
    if (isNaN(number)) {
        return "$0.00";
    } else {
        const usdAmount = '$' + number.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return usdAmount;
    }
}

function formatNumber(string) {
    console.log("format number");
    const number = parseFloat(string);
    if (isNaN(number)) {
        return "0";
    }
    return number.toLocaleString('en-US', {
        maximumFractionDigits: 2
    });
}

module.exports = {
    parsePercentage,
    getDoesStringContainString,
    formatStringDollars,
    formatNumber
}
