import React from 'react'
import LoadingIcon from './LoadingIcon';
export default function SystemImage(props) {
    const {
        isLoading,
        imageSrc,
        zoom
    } = props;
    const backgroundStyle = {
        backgroundColor: "white",
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: `${!!zoom ? zoom : 300}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        paddingTop: '56.25%'
    };
    const backgroundStyleLoading = {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
        background: '#e6e6e6'
    }
    const svgWrapperStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    let className = "proposal-card grid-card-image ";
    if (!!props.className) {
        className += props.className;
    }
    console.log({className});
    if (!!isLoading) {
        return (
            <div style={backgroundStyleLoading} className={className}>
                <div style={svgWrapperStyles}>
                    <LoadingIcon thickness={300} color="#ffffff" secondaryColor="#e6e6e6" style={{ "maxWidth": "100%", "maxHeight": "100%" }} />
                </div>
            </div>
        )
    } else if (!!props.imageSrc) {
        return (
            <div className={className} style={backgroundStyle}></div>
        )
    } else {
        return (
            <div className={className}>
                <p>Your system image is being generated. Please refresh the page in a bit!</p>
            </div>
        )
    }
}
